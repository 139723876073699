<template>
	<div class="historybbb">
		<div class="box">
			<p class="tit">传感器编号：001（这是备注内容）</p>
			<img class="img" src="@/assets/images/line4.png" alt="" />
			<div class="dat">
				<p>开始结束时间:</p>
				<div class="for">
					<el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
				</div>
				<div>
					<el-button plain class="setBtn">重置</el-button>
					<el-button class="subBtn" type="primary">查询</el-button>
				</div>
			</div>
			<img class="img" src="@/assets/images/line4.png" alt="" />
			<div id="ec4" style="width: 1000px; height: 300px"></div>
		</div>
		<!-- <div class="box">
			<p class="tit">传感器编号：002（这是备注内容）</p>
			<img class="img" src="@/assets/images/line4.png" alt="" />
			<div class="dat">
				<p>开始结束时间:</p>
				<div class="for">
					<el-date-picker v-model="value2" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
				</div>
				<div>
					<el-button plain class="setBtn">重置</el-button>
					<el-button class="subBtn" type="primary">查询</el-button>
				</div>
			</div>
			<img class="img" src="@/assets/images/line4.png" alt="" />
			<div id="ec5" style="width: 1000px; height: 300px"></div>
		</div> -->
	</div>
</template>

<script>
	import * as echarts from "echarts";
	export default {
		props: ["id", "name", "bnum"],

		data() {
			return {
				value2: "",
				value1: "",
				input: "",
				startTime: "",
				endTime: "",
				preList: [],
			};
		},
		watch: {
			input() {
				// 分页列表查询
				this.getIotDataPreAlarmData();
			},
		},
		mounted() {
			// this.setEc1();
			// this.setEc2();
		},
		created() {
			if (this.name == "eleven") {
				// 报警预览
				this.getIotDataPreAlarmData();
			}
		},
		methods: {
			getInit() {
				this.getIotDataPreAlarmData();
			},
			// 报警预览
			getIotDataPreAlarmData() {
				this.$API
					.iotResearchFun({
						startTime: this.startTime,
						endTime: this.endTime,
						// axisId: this.id,
						// axisId: "1772093709823774722",
						deviceId: '86040101',
					})
					.then((res) => {
						if (res.code == 1) {
							console.log(res.data.records);
							this.preList = res.data.records;
							var chartDom = document.getElementById("ec4");
							var myChart = echarts.init(chartDom);
							console.log(myChart, 'zheshitubiao')
							let xarr = []
							let yarr = []
							let zarr = []
							let times = []
							if (this.bnum == '24007') {
								this.preList.map(item => {
									console.log(JSON.parse(item.xData).sort((a, b) => b - a))
									xarr.push(JSON.parse(item.xData).sort((a, b) => b - a)[0])
									yarr.push(JSON.parse(item.yData).sort((a, b) => b - a)[0])
									zarr.push(JSON.parse(item.zData).sort((a, b) => b - a)[0])
									// xarr.push(...JSON.parse(item.xData).sort((a, b) => a - b)[])
									// yarr.push(...JSON.parse(item.yData))
									// zarr.push(...JSON.parse(item.zData))
									times.push(item.createTime.split(' ')[0])
								})
							}
							var option2 = {
								title: {
									text: '各轴设备'
								},
								tooltip: {
									trigger: 'axis'
								},
								legend: {
									data: ['x轴数据', 'y轴数据', 'z轴数据']
								},
								grid: {
									left: '3%',
									right: '4%',
									bottom: '3%',
									containLabel: true
								},
								toolbox: {
									feature: {
										saveAsImage: {}
									}
								},
								xAxis: {
									type: 'category',
									boundaryGap: false,
									data: times
								},
								yAxis: {
									type: 'value'
								},
								series: [{
										name: 'x轴数据',
										type: 'line',
										stack: 'Total',
										data: xarr
									},
									{
										name: 'y轴数据',
										type: 'line',
										stack: 'Total',
										data: yarr
									},
									{
										name: 'z轴数据',
										type: 'line',
										stack: 'Total',
										data: zarr
									}
								],
								dataZoom: [{
									type: 'slider', //滑动条型
									start: 0, //滑动条起始位置
									end: 100 //滑动条结束位置
								}],
							};
							myChart.setOption(option2);
							// if (this.preList.length) {
							//   // 处理x轴

							//   this.preList[0].data.forEach((item) => {
							//     console.log(item[0]);
							//     this.xzhou.push(this.timestampToTime(item[0]));
							//   });

							//   // 处理y轴
							//   this.preList.forEach((item) => {
							//     this.nameList.push(item.name);
							//     this.yzhou.push({
							//       symbol: "none",
							//       name: item.name,
							//       type: "line",
							//       stack: "Total",
							//       data1: item.data,
							//     });
							//   });

							//   this.yzhou = this.yzhou.map((item) => {
							//     item.data = [];
							//     item.data1.forEach((item1) => {
							//       item.data.push(item1[1]);
							//     });
							//     return { ...item };
							//   });

							//   this.setEc1();
							// }
						} else {
							this.$message.info(res.message);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			setEc2() {
				var chartDom = document.getElementById("ec5");
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					color: [
						"#6379f2",
						"#7bd365",
						"#6095f8",
						"#6e63f2",
						"#fcc46b",
						"#4c8148",
					],
					title: {
						text: "",
					},
					tooltip: {
						trigger: "axis",
					},
					legend: {
						data: [
							"D01 (表面质量相关特征)",
							"D02 (尺寸相关特征)",
							"D03 (表面质量相关特征)",
							"D04 (尺寸相关特征)",
							"D05 (表面质量相关特征)",
							"D06 (尺寸相关特征)",
						],
					},
					grid: {
						left: "3%",
						right: "4%",
						bottom: "3%",
						containLabel: true,
					},
					// toolbox: {
					//   feature: {
					//     saveAsImage: {},
					//   },
					// },
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: [
							"2024-2-2",
							"2024-2-3",
							"2024-2-4",
							"2024-2-5",
							"2024-2-6",
							"2024-2-7",
							"2024-2-8",
						],
					},
					yAxis: {
						type: "value",
					},
					series: [{
							symbol: "none",
							name: "D01 (表面质量相关特征)",
							type: "line",
							stack: "Total",
							data: [120, 132, 101, 134, 90, 230, 210],
						},
						{
							symbol: "none",

							name: "D02 (尺寸相关特征)",
							type: "line",
							stack: "Total",
							data: [220, 182, 191, 234, 290, 330, 310],
						},
						{
							symbol: "none",

							name: "D03 (表面质量相关特征)",
							type: "line",
							stack: "Total",
							data: [150, 232, 201, 154, 190, 330, 410],
						},
						{
							symbol: "none",

							name: "D04 (尺寸相关特征)",
							type: "line",
							stack: "Total",
							data: [320, 332, 301, 334, 390, 330, 320],
						},
						{
							symbol: "none",

							name: "D05 (表面质量相关特征)",
							type: "line",
							stack: "Total",
							data: [820, 932, 901, 934, 1290, 1330, 1320],
						},
						{
							symbol: "none",

							name: "D06 (尺寸相关特征)",
							type: "line",
							stack: "Total",
							data: [820, 932, 901, 934, 1290, 1330, 1320],
						},
					],
				};
				option && myChart.setOption(option);
			},
			setEc1() {
				var chartDom = document.getElementById("ec4");
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					color: [
						"#6379f2",
						"#7bd365",
						"#6095f8",
						"#6e63f2",
						"#fcc46b",
						"#4c8148",
					],
					title: {
						text: "",
					},
					tooltip: {
						trigger: "axis",
					},
					legend: {
						data: [
							"D01 (表面质量相关特征)",
							"D02 (尺寸相关特征)",
							"D03 (表面质量相关特征)",
							"D04 (尺寸相关特征)",
							"D05 (表面质量相关特征)",
							"D06 (尺寸相关特征)",
						],
					},
					grid: {
						left: "3%",
						right: "4%",
						bottom: "3%",
						containLabel: true,
					},
					// toolbox: {
					//   feature: {
					//     saveAsImage: {},
					//   },
					// },
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: [
							"2024-2-2",
							"2024-2-3",
							"2024-2-4",
							"2024-2-5",
							"2024-2-6",
							"2024-2-7",
							"2024-2-8",
						],
					},
					yAxis: {
						type: "value",
					},
					series: [{
							symbol: "none",
							name: "D01 (表面质量相关特征)",
							type: "line",
							stack: "Total",
							data: [120, 132, 101, 134, 90, 230, 210],
						},
						{
							symbol: "none",

							name: "D02 (尺寸相关特征)",
							type: "line",
							stack: "Total",
							data: [220, 182, 191, 234, 290, 330, 310],
						},
						{
							symbol: "none",

							name: "D03 (表面质量相关特征)",
							type: "line",
							stack: "Total",
							data: [150, 232, 201, 154, 190, 330, 410],
						},
						{
							symbol: "none",

							name: "D04 (尺寸相关特征)",
							type: "line",
							stack: "Total",
							data: [320, 332, 301, 334, 390, 330, 320],
						},
						{
							symbol: "none",

							name: "D05 (表面质量相关特征)",
							type: "line",
							stack: "Total",
							data: [820, 932, 901, 934, 1290, 1330, 1320],
						},
						{
							symbol: "none",

							name: "D06 (尺寸相关特征)",
							type: "line",
							stack: "Total",
							data: [820, 932, 901, 934, 1290, 1330, 1320],
						},
					],
				};
				option && myChart.setOption(option);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.historybbb {
		.img {
			width: 1088px;
			margin-bottom: 16px;
		}

		.box {
			box-sizing: border-box;
			width: 100%;
			height: 458px;
			background: #ffffff;
			border-radius: 8px 8px 8px 8px;
			padding: 24px;
			margin-bottom: 16px;
		}

		.subBtn {
			background: #ed1b23 !important;
			border-color: #ed1b23 !important;
		}

		.setBtn {
			background: #edefef !important;
			border-color: #edefef !important;
		}

		.tit {
			font-weight: bold;
			color: #333333;
			// padding-bottom: 16px;
			// border-bottom: 1px dotted #eeeeee;
			margin-bottom: 20px;
		}

		.dat {
			display: flex;
			align-items: center;
			// padding-bottom: 16px;
			// border-bottom: 1px dotted #f9f9f9;
			margin-bottom: 16px;
		}

		.for {
			width: 250px;
			margin-left: 20px;
			margin-right: 48px;
		}

		.el-range-editor {
			width: 250px;
		}

		.count {
			color-scheme: #333333;
			margin-bottom: 20px;
		}
	}
</style>