<template>
	<div class="historybbb">
		<div class="box" style="height: auto">
			<!-- <p class="tit">月度统计</p> -->
			<div class="dat">
				<p>开始结束时间1:</p>
				<div class="for">
					<el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" format="yyyy - MM - dd" value-format="yyyy-MM-dd" @change="changeFun">
					</el-date-picker>
				</div>
				<p>轴名称:</p>
				<div class="for">
					<el-select v-model="input" placeholder="请选择">
						<el-option v-for="item in zhouList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<!-- <el-input v-model="input" placeholder="请输入"></el-input> -->
				</div>
				<div>
					<el-button plain class="setBtn" @click="resetFun">重置</el-button>
					<el-button class="subBtn" type="primary" @click="getIotDataPreAlarmData">查询</el-button>
				</div>
			</div>
			<img class="img" src="@/assets/images/line4.png" alt="" />
			<!-- <p class="count">报警统计</p> -->
			<div id="ec300" style="width: 1000px; height: 400px"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from "echarts";
	// import moment from 'moment';
	export default {
		props: ["id", "name", 'bnum'],

		data() {
			return {
				value1: "",
				input: "",
				startTime: "2022-01-02",
				endTime: "2026-03-31",
				xzhou: [],
				yzhou: [],
				nameList: [],
				zhouList: [],
			};
		},
		watch: {
			input() {
				// 分页列表查询
				this.getIotDataPreAlarmData();
			},
		},
		mounted() {
			// this.setEc1();
		},
		created() {
		console.log(this.bnum, 'bnum=====================');
		
			if (this.name == "second") {
				// 报警预览
				this.getIotDataPreAlarmData();
			}
		},
		methods: {
			getInit() {
				this.getIotDataPreAlarmData();
			},
			timestampToTime(timestamp) {
				console.log(timestamp, "nnnn");

				// let a = timestamp.toString().substring(0, timestamp.length - 3);
				let a = timestamp.toString();

				console.log(a, "===a");

				var date = new Date(Number(a));
				console.log(date, "datedate");

				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var day = date.getDate();
				var hours = date.getHours();
				var minutes = "0" + date.getMinutes();
				var seconds = "0" + date.getSeconds();
				console.log(minutes, '====minutes');
				console.log(seconds, '===seconds');
				
				

				var formattedTime = year + "-" + month + "-" + day
				 +
				" " +
				hours +
				":" +
				minutes+
				":" +
				seconds;

				return formattedTime;
			},

			// 报警预览
			getZhouListData() {
				this.$API
					.iotGetZhouList({
						// startTime: this.startTime,
						// endTime: this.endTime,
						// // axisId: this.id,
						// axisId: "1772093709823774722",
					})
					.then((res) => {
						if (res.code == 1) {
							this.zhouList = res.data.records;
							this.zhouList = this.zhouList.map((item) => {
								item.label = item.axisName;
								item.value = item.axisNo;
								return {
									...item
								};
							});
							// if (this.zhouList.length) {
							// 	this.input = this.zhouList[0].value;
							// 	// 分页列表查询
							// 	this.getIotDataPreAlarmData();
							// }
							console.log(this.zhouList, "=== this.zhouList===");
						} else {
							this.$message.info(res.message);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},

			// 报警预览
			getIotDataPreAlarmData() {
				this.$API
					.iotHistoryList({
						startTime: this.startTime,
						endTime: this.endTime,
						// axisId: this.id,
						axisId: "1772093709823774722",
						// axisId: this.input,
					})
					.then((res) => {
						if (res.code == 200) {
							// console.log('这是内容', res, moment())
							this.preList = res.data;
							console.log(this.preList, '=\=======prelist');
							

							if (this.preList.length) {
								// 处理x轴

								this.preList[0].data.forEach((item) => {
									// console.log(item[0]);
									this.xzhou.push(this.timestampToTime(item[0]));
								});

								// 处理y轴
								this.preList.forEach((item) => {
									this.nameList.push(item.name);
									this.yzhou.push({
										symbol: "none",
										name: item.name,
										type: "line",
										stack: "Total",
										data1: item.data,
									});
								});

								this.yzhou = this.yzhou.map((item) => {
									item.data = [];
									item.data1.forEach((item1) => {
										item.data.push(item1[1]);
									});
									return {
										...item
									};
								});
								console.log(this.xzhou, 'xzhou================');
								console.log(this.yzhou, '=============yzhou');
								
								
								this.setEc1();
							}
						} else {
							this.$message.info(res.message);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 重置
			resetFun() {
				this.value1 = "";
				this.input = "";
				this.startTime = "";
				this.endTime = "";
				// 分页列表查询
				this.getAllList();
			},
			// 时间改变函数
			changeFun() {
				console.log(this.value1);
				if (this.value1 && this.value1.length) {
					this.startTime = this.value1[0];
					this.endTime = this.value1[1];
				} else {
					this.startTime = "";
					this.endTime = "";
				}
				// 分页列表查询
				this.getAllList();
			},
			setEc1(res) {
				var chartDom = document.getElementById("ec300");
				var myChart = echarts.init(chartDom);
				var option;

				var option1 = {
					xAxis: {
						type: 'category',
						data: this.bnum == 'B3G140245' ? this.xzhou : 0
						// data: this.xzhou
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						// data:  this.yzhou[0].data,
						data: this.bnum == 'B3G140245' ? this.yzhou[0].data : 0,
						type: 'line',
						color: '#c23632'
					}],
					dataZoom: [{
						type: 'slider', //滑动条型
						xAxisIndex: 0, //指定控制的x轴
						start: 0, //滑动条起始位置
						end: 100 //滑动条结束位置
					}],
				}
				console.log(option1,'=====option1=====')
				myChart.setOption(option1);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.historybbb {
		.img {
			width: 1088px;
			margin-bottom: 16px;
		}

		.box {
			box-sizing: border-box;
			width: 100%;
			height: 458px;
			background: #ffffff;
			border-radius: 8px 8px 8px 8px;
			padding: 24px;
			margin-bottom: 16px;
		}

		.subBtn {
			background: #ed1b23 !important;
			border-color: #ed1b23 !important;
		}

		.setBtn {
			background: #edefef !important;
			border-color: #edefef !important;
		}

		.tit {
			font-weight: bold;
			color: #333333;
			padding-bottom: 16px;
			border-bottom: 1px dotted #eeeeee;
			margin-bottom: 20px;
		}

		.dat {
			display: flex;
			align-items: center;
			// padding-bottom: 16px;
			// border-bottom: 1px dotted #f9f9f9;
			margin-bottom: 16px;
		}

		.for {
			width: 280px;
			margin-left: 20px;
			margin-right: 48px;
		}

		.el-range-editor {
			width: 280px;
		}

		.count {
			color-scheme: #333333;
			margin-bottom: 20px;
		}
	}
</style>