export const PoliceStatus = [
  {
    value: 1,
    label: "报警",
  },
  {
    value: 2,
    label: "运行",
  },
  {
    value: 3,
    label: "待机",
  },
  {
    value: 4,
    label: "离线",
  },
  {
    value: 5,
    label: "调试",
  },
];
