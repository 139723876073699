<template>
	<div class="hitpolice">
		<div class="box box1">
			<p class="tit">月度统计</p>
			<img class="img" src="@/assets/images/line4.png" alt="" />
			<div class="show">
				<div class="item" v-for="(item, n) in preList" :key="n">
					<div class="line11">
						<div class="left">
							<img class="img1" src="@/assets/images/line4.png" alt="" /><span class="sp">{{
                  isObjectEmpty(item.deviceName) ? item.deviceName : "-"
                }}</span>{{ isObjectEmpty(item.axisName) ? item.axisName : "-" }}
						</div>

						<div class="right">
							<img class="img2" src="@/assets/images/line4.png" alt="" />
						</div>
					</div>
					<div class="line11" style="border-bottom: none; margin-bottom: 0">
						<div class="left">
							<img class="img2 m12" src="@/assets/images/line4.png" alt="" />安装后保护次数：
						</div>

						<div class="right">
							{{ isObjectEmpty(item.protectNum) ? item.protectNum : "-" }}
						</div>
					</div>
					<div class="line11" style="border-bottom: none; margin-bottom: 0">
						<div class="left">
							<img class="img2 m12" src="@/assets/images/line4.png" alt="" />平均报警问题
						</div>

						<div class="right">
							{{ isObjectEmpty(item.averageAlarm) ? item.averageAlarm : "-" }}
						</div>
					</div>
					<div class="line11" style="border-bottom: none; margin-bottom: 0">
						<div class="left">
							<img class="img2 m12" src="@/assets/images/line4.png" alt="" />预计挽回损失
						</div>

						<div class="right">
							{{ isObjectEmpty(item.expectedSave) ? item.expectedSave : "-" }}
						</div>
					</div>
					<div class="line11" style="border-bottom: none; margin-bottom: 0">
						<div class="left">
							<img class="img2 m12" src="@/assets/images/line4.png" alt="" />最新报警时间
						</div>

						<div class="right">2024-02-12</div>
					</div>
				</div>
			</div>
		</div>
		<div class="box" style="height: auto">
			<p class="tit">月度统计</p>
			<img class="img" src="@/assets/images/line4.png" alt="" />
			<div class="dat">
				<p>开始结束时间:</p>
				<div class="for">
					<el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" format="yyyy - MM - dd" value-format="yyyy-MM-dd" @change="changeFun">
					</el-date-picker>
				</div>
				<div>
					<el-button plain class="setBtn" @click="resetFun">重置</el-button>
					<el-button class="subBtn" type="primary" @click="getAllList">查询</el-button>
				</div>
			</div>
			<!-- 表格展示 start -->
			<el-row class="table-box">
				<el-table :header-cell-style="{ background: '#F4F4F4', height: '56px' }" :data="tableData" style="width: 100%"
					stripe header-cell-class-name="table-header" :key="Math.random()">
					<el-table-column label="序号" type="index" width="50"></el-table-column>
					<el-table-column prop="deviceOnlyCode" label="设备编号" width="265" :key="Math.random()">
						<template slot-scope="{ row }">{{
              row.deviceOnlyCode || "-"
            }}</template>
					</el-table-column>
					<el-table-column prop="axisName" label="轴名称" width="186" :key="Math.random()">
						<template slot-scope="{ row }">{{ row.axisName || "-" }}</template>
					</el-table-column>
					<el-table-column prop="channelNo" label="通道" width="86" :key="Math.random()">
						<template slot-scope="{ row }">{{ row.channelNo || "-" }}</template>
					</el-table-column>
					<el-table-column prop="alarmTime" label="报警时间" width="189" :key="Math.random()">
						<template slot-scope="{ row }">{{ row.alarmTime || "-" }}</template>
					</el-table-column>

					<el-table-column align="center" prop="status" label="操作" :key="Math.random()">
						<!--  slot-scope="scope" -->
						<template slot-scope="scope">
							<!-- IOT数据 -->
							<span @click="seeDetail(scope.row)" class="f14 see p b">
								查看详情
							</span>
						</template>
					</el-table-column>
				</el-table>
			</el-row>

			<!-- 表格展示 end -->
			<!-- 分页开始 -->
			<el-pagination background layout="prev, pager, next" :total="listTotal" :page-size="pageSize" :current-page="page"
				@current-change="pageChange" @prev-click="prevChange" @next-click="nextChange">
			</el-pagination>
			<!-- 分页结束 -->
			<!-- 弹窗 -->
			<el-dialog @open="open()" append-to-body :visible.sync="assderVisible1" width="948px" :show-close="false"
				class="assderTc">
				<span slot="title" class="title">
					<p class="flex">
						<img src="@/assets/images/line3.png" class="line2" />
						<span>查看详情</span>
					</p>

					<div>
						<img class="big" src="@/assets/images/big.png" />
						<img @click="assderVisible1 = false" class="clos" src="@/assets/images/clos.png" />
					</div>
				</span>
				<div class="show1">
					<div class="box2">
						<p class="he">
							{{ detail1.channelName ? detail1.channelName : "-" }}
						</p>
						<p class="max">
							<span>通道阈值：{{ detail1.channel ? detail1.channel : "-" }}</span>
							<span>最大值：{{ detail1.total ? detail1.total : "-" }}</span>
						</p>
						<p class="unit">单位：M/s^2</p>
						<div id="ec91" style="width: 400px; height: 170px; margin-left: 20px"></div>
					</div>
					<!-- <div class="box2">
            <p class="he">1(CH)</p>
            <p class="max">
              <span>通道阈值：10</span>
              <span>最大值：18</span>
            </p>
            <p class="unit">单位：M/s^2</p>
            <div
              id="ec92"
              style="width: 400px; height: 170px; margin-left: 20px"
            ></div>
          </div>
          <div class="box2">
            <p class="he">1(CH)</p>
            <p class="max">
              <span>通道阈值：10</span>
              <span>最大值：18</span>
            </p>
            <p class="unit">单位：M/s^2</p>
            <div
              id="ec93"
              style="width: 400px; height: 170px; margin-left: 20px"
            ></div>
          </div>
          <div class="box2">
            <p class="he">1(CH)</p>
            <p class="max">
              <span>通道阈值：10</span>
              <span>最大值：18</span>
            </p>
            <p class="unit">单位：M/s^2</p>
            <div
              id="ec94"
              style="width: 400px; height: 170px; margin-left: 20px"
            ></div>
          </div> -->
				</div>
			</el-dialog>
			<!-- 结束 -->
		</div>
	</div>
</template>

<script>
	import * as echarts from "echarts";
	export default {
		props: ["id", "name", "bnum"],

		data() {
			return {
				assderVisible1: false,
				value1: "",
				input: "",
				tableData: [{}], //表格数据
				listTotal: 0, //列表总数据
				pageSize: 5, //每页展示条数
				page: 1, //当前页码数
				preList: [],
				startTime: "",
				endTime: "",
				detailObj: {},
				detail1: {},
				detail2: {},
				detail1x: [],
				detail1y: [],
			};
		},
		watch: {},
		mounted() {
			// this.setEc1();
			// this.setEc2();
		},
		created() {
			if (this.name == "third") {
				// 分页列表查询
				this.getAllList();
				this.getIotDataPreAlarmData();
			}
		},
		methods: {
			seeDetail(row) {
				console.log(row, "===row");
				this.getIotAlarmInFoDetailFun(row.id);
				this.assderVisible1 = true;
			},
			// 报警预览
			getIotAlarmInFoDetailFun(id) {
				this.$API
					.iotAlarmInFoDetail({
						id: id,
						code: "",
					})
					.then((res) => {
						if (res.code == 200) {
							this.detailObj = res.data;
							if (this.detailObj[0]) {
								this.detail1 = this.detailObj[0];
								this.detail1.acceleration.data.forEach((item) => {
									this.detail1x.push(item[0]);
									this.detail1y.push(item[1]);
								});
							}
							this.setEc2();
							// if (this.detailObj[1]) {
							//   this.detail2 = this.detailObj[1];
							// }
						} else {
							this.$message.info(res.message);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 重置
			resetFun() {
				this.value1 = "";
				this.startTime = "";
				this.endTime = "";
				// 分页列表查询
				this.getAllList();
			},
			isObjectEmpty(obj) {
				return !(Object.keys(obj).length === 0);
			},
			// 时间改变函数
			changeFun() {
				console.log(this.value1);
				if (this.value1 && this.value1.length) {
					this.startTime = this.value1[0];
					this.endTime = this.value1[1];
				} else {
					this.startTime = "";
					this.endTime = "";
				}
				// 分页列表查询
				this.getAllList();
			},
			// 报警预览
			getIotDataPreAlarmData() {
				this.$API
					.iotAlarmOverview({
						code: "",
						// companyId: this.companyId,
					})
					.then((res) => {
						if (res.code == 200) {
							this.preList = this.bnum == '235386' ? res.data : [];
						} else {
							this.$message.info(res.message);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 分页列表查询
			getAllList() {
				let params = {
					deviceOnlyCode: "",
					pageNo: this.page,
					pageSize: this.pageSize,
					startTime: this.startTime,
					endTime: this.endTime,
					// status: Number(this.value),
					// alarmNo: this.input,
					// id: Number(this.id),
				};
				this.$API
					.iotAlarmCacheAlarmList(params)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = [];
							this.listTotal = Number(res.data.total);
							this.tableData = this.bnum == '235386' ? res.data.list : [];
						} else {
							this.$message.info(res.message);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			open() {
				setTimeout(() => {
					this.setEc2();
					this.setEc3();
					this.setEc4();
					this.setEc5();
				}, 8000);
			},
			setEc5() {
				var chartDom = document.getElementById("ec94");
				// var chartDom = this.$refs.ec91;

				var myChart = echarts.init(chartDom);
				var option;

				option = {
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
					},
					yAxis: {
						type: "value",
					},
					grid: {
						top: "10px",
						left: "30px",
						right: "10px",
						bottom: "20px",
					},
					series: [{
						data: [100, 932, 901, 100, 1290, 1330, 1320],
						type: "line",
						lineStyle: {
							color: "#ed1b23", // 设置线的颜色为红色
						},
						itemStyle: {
							//设置端点颜色
							normal: {
								color: "#ed1b23", // 设置线条上点的颜色（和图例的颜色）
							},
						},
					}, ],
				};
				option && myChart.setOption(option);
			},
			setEc4() {
				var chartDom = document.getElementById("ec93");
				// var chartDom = this.$refs.ec91;

				var myChart = echarts.init(chartDom);
				var option;

				option = {
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
					},
					yAxis: {
						type: "value",
					},
					grid: {
						top: "10px",
						left: "30px",
						right: "10px",
						bottom: "20px",
					},
					series: [{
						data: [100, 932, 901, 100, 1290, 1330, 1320],
						type: "line",
						lineStyle: {
							color: "#ed1b23", // 设置线的颜色为红色
						},
						itemStyle: {
							//设置端点颜色
							normal: {
								color: "#ed1b23", // 设置线条上点的颜色（和图例的颜色）
							},
						},
					}, ],
				};
				option && myChart.setOption(option);
			},
			setEc3() {
				var chartDom = document.getElementById("ec92");
				// var chartDom = this.$refs.ec91;

				var myChart = echarts.init(chartDom);
				var option;

				option = {
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
					},
					yAxis: {
						type: "value",
					},
					grid: {
						top: "10px",
						left: "30px",
						right: "10px",
						bottom: "20px",
					},
					series: [{
						data: [100, 932, 901, 100, 1290, 1330, 1320],
						type: "line",
						lineStyle: {
							color: "#ed1b23", // 设置线的颜色为红色
						},
						itemStyle: {
							//设置端点颜色
							normal: {
								color: "#ed1b23", // 设置线条上点的颜色（和图例的颜色）
							},
						},
					}, ],
				};
				option && myChart.setOption(option);
			},
			setEc2() {
				var chartDom = document.getElementById("ec91");
				// var chartDom = this.$refs.ec91;

				var myChart = echarts.init(chartDom);
				var option;

				option = {
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: this.detail1x,
					},
					yAxis: {
						type: "value",
					},
					grid: {
						top: "10px",
						left: "30px",
						right: "10px",
						bottom: "20px",
					},
					series: [{
						data: this.detail1y,
						type: "line",
						lineStyle: {
							color: "#ed1b23", // 设置线的颜色为红色
						},
						itemStyle: {
							//设置端点颜色
							normal: {
								color: "#ed1b23", // 设置线条上点的颜色（和图例的颜色）
							},
						},
					}, ],
				};
				option && myChart.setOption(option);
			},
			// 当前页码改变
			pageChange(page) {
				console.log("page", page);
				this.page = page;
				this.tableData = [];
				this.getAllList();
			},
			// 点击上一页
			prevChange() {},
			// 点击下一页
			nextChange() {},
			setEc1() {
				var chartDom = document.getElementById("ec3");
				var myChart = echarts.init(chartDom);
				var option;

				// option = {
				//   color: [
				//     "#6379f2",
				//     "#7bd365",
				//     "#6095f8",
				//     "#6e63f2",
				//     "#fcc46b",
				//     "#4c8148",
				//   ],
				//   title: {
				//     text: "",
				//   },
				//   tooltip: {
				//     trigger: "axis",
				//   },
				//   legend: {
				//     data: [
				//       "D01 (表面质量相关特征)",
				//       "D02 (尺寸相关特征)",
				//       "D03 (表面质量相关特征)",
				//       "D04 (尺寸相关特征)",
				//       "D05 (表面质量相关特征)",
				//       "D06 (尺寸相关特征)",
				//     ],
				//   },
				//   grid: {
				//     left: "3%",
				//     right: "4%",
				//     bottom: "3%",
				//     containLabel: true,
				//   },
				//   // toolbox: {
				//   //   feature: {
				//   //     saveAsImage: {},
				//   //   },
				//   // },
				//   xAxis: {
				//     type: "category",
				//     boundaryGap: false,
				//     data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
				//   },
				//   yAxis: {
				//     type: "value",
				//   },
				//   series: [
				//     {
				//       symbol: "none",
				//       name: "D01 (表面质量相关特征)",
				//       type: "line",
				//       stack: "Total",
				//       data: [120, 132, 101, 134, 90, 230, 210],
				//     },
				//     {
				//       symbol: "none",

				//       name: "D02 (尺寸相关特征)",
				//       type: "line",
				//       stack: "Total",
				//       data: [220, 182, 191, 234, 290, 330, 310],
				//     },
				//     {
				//       symbol: "none",

				//       name: "D03 (表面质量相关特征)",
				//       type: "line",
				//       stack: "Total",
				//       data: [150, 232, 201, 154, 190, 330, 410],
				//     },
				//     {
				//       symbol: "none",

				//       name: "D04 (尺寸相关特征)",
				//       type: "line",
				//       stack: "Total",
				//       data: [320, 332, 301, 334, 390, 330, 320],
				//     },
				//     {
				//       symbol: "none",

				//       name: "D05 (表面质量相关特征)",
				//       type: "line",
				//       stack: "Total",
				//       data: [820, 932, 901, 934, 1290, 1330, 1320],
				//     },
				//     {
				//       symbol: "none",

				//       name: "D06 (尺寸相关特征)",
				//       type: "line",
				//       stack: "Total",
				//       data: [820, 932, 901, 934, 1290, 1330, 1320],
				//     },
				//   ],
				// };
				option && myChart.setOption(option);
			},
		},
	};
</script>

<style lang="scss">
	// 查看详情弹窗
	.assderTc {
		.show1 {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 0 20px 0px 20px;
		}

		.box2 {
			width: 440px;
			height: 312px;
			background: #ffffff;
			border-radius: 0px 0px 0px 0px;
			border: 1px solid #dedede;
			opacity: 0.72;
			margin-bottom: 20px;
		}

		.big {
			width: 16px;
			height: 16px;
			margin-right: 24px;
		}

		.clos {
			width: 16px;
			height: 16px;
		}

		.el-dialog__body {
			height: 250px;
			padding: 0px;
		}

		.el-dialog__header {
			background: #fff;
			border-radius: 4px 4px 0px 0px;
			// border: 1px solid #e2e2e2;
			padding: 0px !important;
			// padding-top: 18px;
			// padding-bottom: 18px;
			// padding-right: 32px;
			// padding-left: 32px;

			.title {
				box-sizing: border-box;
				padding: 0 32px;
				height: 46px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: #333333;
				background: #f9f9f9;
				font-size: 22px;
				margin-bottom: 24px;

				.line2 {
					width: 4px;
					height: 14px;
					margin-right: 10px;
				}
			}
		}

		.he,
		.max {
			width: 438px;
			height: 36px;
			background: #f7f7f7;
			box-sizing: border-box;
			padding-left: 20px;
			padding-right: 20px;
			line-height: 36px;
			font-weight: 500;
			font-size: 14px;
			color: #333333;
			margin-bottom: 12px;
		}

		.max {
			display: flex;
			justify-content: space-between;
			background-color: #fff;
		}

		.unit {
			font-weight: 400;
			font-size: 12px;
			color: #333333 !important;
			margin-bottom: 17px;
			padding-left: 20px;
		}
	}

	// 表格
	.table-box {
		margin-bottom: 23px;

		// min-height: 238px;
		.handle {
			ul {
				padding: 0 20px;
				display: flex;
				align-items: center;
			}

			li {
				float: left;
				font-size: 14px;
				font-weight: 400;
				color: #2a89ff;
				line-height: 22px;
			}

			.line {
				width: 0px;
				height: 13px;
				border: 1px solid #c6ccda;
				margin: 4px 4px;
			}
		}
	}

	.hitpolice {
		.box1 {
			height: auto !important;
		}

		.show {
			display: flex;
			flex-wrap: wrap;
		}

		.item {
			width: 345px;
			height: 216px;
			background: linear-gradient(180deg, #fdeded 0%, #fff9f9 11%, #faf7f7 100%);
			border-radius: 16px 16px 16px 16px;
			margin-right: 12px;
			margin-bottom: 12px;
			padding: 20px;
			box-sizing: border-box;
		}

		.img1 {
			width: 24px;
			height: 24px;
			margin-right: 12px;
			position: relative;
			top: 5px;
		}

		.m12 {
			margin-right: 12px;
		}

		.img2 {
			width: 16px;
			height: 16px;
			position: relative;
			top: 3px;
		}

		.line11 {
			display: flex;
			justify-content: space-between;
			padding-bottom: 14px;
			border-bottom: 1px solid #eeeeee;
			font-weight: 400;
			font-size: 14px;
			color: #333333;
			align-items: center;
			vertical-align: middle;
			margin-bottom: 13px;
		}

		.sp {
			margin-right: 12px;
			font-weight: 500;
			font-size: 18px;
			color: #333333;
		}

		.img {
			width: 1088px;
			margin-bottom: 16px;
		}

		.box {
			box-sizing: border-box;
			width: 100%;
			height: 458px;
			background: #ffffff;
			border-radius: 8px 8px 8px 8px;
			padding: 24px;
			margin-bottom: 16px;
		}

		.subBtn {
			background: #ed1b23 !important;
			border-color: #ed1b23 !important;
		}

		.setBtn {
			background: #edefef !important;
			border-color: #edefef !important;
		}

		.tit {
			font-weight: bold;
			color: #333333;
			// padding-bottom: 16px;
			// border-bottom: 1px dotted #eeeeee;
			margin-bottom: 20px;
		}

		.dat {
			display: flex;
			align-items: center;
			// padding-bottom: 16px;
			// border-bottom: 1px dotted #f9f9f9;
			margin-bottom: 16px;
		}

		.for {
			width: 260px;
			margin-left: 20px;
			margin-right: 48px;
		}

		.el-range-editor {
			width: 260px;
		}

		.count {
			color-scheme: #333333;
			margin-bottom: 20px;
		}
	}
</style>