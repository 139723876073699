<template>
	<div class="deviceparam">
		<div class="box">
			<div class="hea">
				<img class="img" src="@/assets/images/line3.png" alt="" />
				<p>基础数据</p>
			</div>
			<div class="item">
				<div class="line line11">
					<div class="tit">
						<p>每天开机时间</p>
						<div class="mul">
							<div @click="openT = 1" :class="['mli', openT == 1 ? 'mac' : '']">
								本周
							</div>
							<div @click="openT = 2" :class="['mli', openT == 2 ? 'mac' : '']">
								本月
							</div>
							<div @click="openT = 3" :class="['mli', openT == 3 ? 'mac' : '']">
								自定义
								<div class="datebox">
									<el-date-picker v-model="value8" type="daterange" range-separator="至" start-placeholder="开始日期"
										end-placeholder="结束日期" format="yyyy - MM - dd" value-format="yyyy-MM-dd" @change="changeFun">
									</el-date-picker>
								</div>
							</div>
						</div>
					</div>
					<div id="ec10" style="width: 270px; height: 170px"></div>
				</div>
				<div class="line line11">
					<div class="tit">
						<p>报警次数</p>
						<div class="mul">
							<div @click="alarmT = 1" :class="['mli', alarmT == 1 ? 'redmac' : '']">
								本周
							</div>
							<div @click="alarmT = 2" :class="['mli', alarmT == 2 ? 'redmac' : '']">
								本月
							</div>
							<div @click="alarmT = 3" :class="['mli', alarmT == 3 ? 'redmac' : '']">
								自定义
								<div class="datebox">
									<el-date-picker v-model="value9" type="daterange" range-separator="至" start-placeholder="开始日期"
										end-placeholder="结束日期" format="yyyy - MM - dd" value-format="yyyy-MM-dd" @change="changeFun1">
									</el-date-picker>
								</div>
							</div>
						</div>
					</div>
					<div id="ec11" style="width: 270px; height: 170px"></div>
				</div>
				<div class="line line22">
					<div class="tit">
						<p>报警号排名top10</p>
					</div>
					<div class="list1">
						<div class="lista" v-if="alarmObj && alarmObj.length">
							<div class="lis">
								<div class="left">报警号</div>
								<div class="right">报警次数</div>
							</div>
							<div class="lis1" v-for="(item, n) in alarmObj" :key="n">
								<div class="left">{{ item.alarmNo ? item.alarmNo : "-" }}</div>
								<div class="right">{{ item.num ? item.num : "-" }}</div>
							</div>
						</div>
						<div class="lista">
							<el-empty description="暂无排名"></el-empty>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="line2">
			<div class="line22">
				<div class="hea">
					<img class="img" src="@/assets/images/line3.png" alt="" />
					<p>NC数据</p>
				</div>
				<div class="body">
					<div class="b1">
						<p>NC模式：</p>
						<p class="b2">
							{{ dataObj && dataObj.ncMode ? dataObj.ncMode : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>程序名称：</p>
						<p class="b2">
							{{ dataObj && dataObj.programName ? dataObj.programName : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>加工时间：</p>
						<p class="b2">
							{{ dataObj && dataObj.workTime ? dataObj.workTime : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>加工节拍：</p>
						<p class="b2">
							{{ dataObj && dataObj.workBeat ? dataObj.workBeat : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>产量：</p>
						<p class="b2">
							{{ dataObj && dataObj.count ? dataObj.count : "-" }}
						</p>
					</div>
				</div>
			</div>
			<div class="line22" style="margin-right: 0">
				<div class="hea">
					<img class="img" src="@/assets/images/line3.png" alt="" />
					<p>运行数据</p>
				</div>
				<div class="body">
					<div class="b1">
						<p>进给值：</p>
						<p class="b2">
							{{ dataObj && dataObj.feed_speed ? dataObj.feed_speed : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>进给倍率：</p>
						<p class="b2">
							{{ dataObj && dataObj.feedRate ? dataObj.feedRate : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>主轴转速：</p>
						<p class="b2">
							{{ dataObj && dataObj.spnSpeed ? dataObj.spnSpeed : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>主轴倍率：</p>
						<p class="b2">
							{{ dataObj && dataObj.spnRate ? dataObj.spnRate : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>x轴坐标值：</p>
						<p class="b2">
							{{ dataObj && dataObj.posX ? dataObj.posX : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>y轴坐标值：</p>
						<p class="b2">
							{{ dataObj && dataObj.posY ? dataObj.posY : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>z轴坐标值：</p>
						<p class="b2">
							{{ dataObj && dataObj.posZ ? dataObj.posZ : "-" }}
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="line2" style="margin-top: 16px">
			<div class="line22" style="height: 395px">
				<div class="hea">
					<img class="img" src="@/assets/images/line3.png" alt="" />
					<p>主轴负载</p>
				</div>
				<div class="zhu">
					<div class="tit">
						<p></p>
						<div class="mul">
							<div @click="zhuT = 1" :class="['mli', zhuT == 1 ? 'mac' : '']">
								本周
							</div>
							<div @click="zhuT = 2" :class="['mli', zhuT == 2 ? 'mac' : '']">
								本月
							</div>
							<div @click="zhuT = 3" :class="['mli', zhuT == 3 ? 'mac' : '']">
								自定义
								<div class="datebox">
									<el-date-picker v-model="value10" type="daterange" range-separator="至" start-placeholder="开始日期"
										end-placeholder="结束日期" format="yyyy - MM - dd" value-format="yyyy-MM-dd" @change="changeFun2">
									</el-date-picker>
								</div>
							</div>
						</div>
					</div>
					<div id="ec12" style="width: 470px; height: 220px"></div>
				</div>
			</div>
			<div class="line22" style="margin-right: 0; height: 395px">
				<div class="hea">
					<img class="img" src="@/assets/images/line3.png" alt="" />
					<p>各轴负载</p>
				</div>
				<div class="zhu">
					<div class="tit">
						<p></p>
						<div class="mul">
							<div @click="xzhuT = 1" :class="['mli', xzhuT == 1 ? 'mac' : '']">
								本周
							</div>
							<div @click="xzhuT = 2" :class="['mli', xzhuT == 2 ? 'mac' : '']">
								本月
							</div>
							<div @click="xzhuT = 3" :class="['mli', xzhuT == 3 ? 'mac' : '']">
								自定义
								<div class="datebox">
									<el-date-picker v-model="value11" type="daterange" range-separator="至" start-placeholder="开始日期"
										end-placeholder="结束日期" format="yyyy - MM - dd" value-format="yyyy-MM-dd" @change="changeFun3">
									</el-date-picker>
								</div>
							</div>
						</div>
					</div>
					<div id="ec13" style="width: 470px; height: 220px"></div>
				</div>
			</div>
		</div>
		<!-- <div class="line2" style="margin-top: 16px">
			<div class="line22" style="height: 395px">
				<div class="hea">
					<img class="img" src="@/assets/images/line3.png" alt="" />
					<p>y轴负载</p>
				</div>
				<div class="zhu">
					<div class="tit">
						<p></p>
						<div class="mul">
							<div @click="yzhuT = 1" :class="['mli', yzhuT == 1 ? 'mac' : '']">
								本周
							</div>
							<div @click="yzhuT = 2" :class="['mli', yzhuT == 2 ? 'mac' : '']">
								本月
							</div>
							<div @click="yzhuT = 3" :class="['mli', yzhuT == 3 ? 'mac' : '']">
								自定义
								<div class="datebox">
									<el-date-picker v-model="value12" type="daterange" range-separator="至" start-placeholder="开始日期"
										end-placeholder="结束日期" format="yyyy - MM - dd" value-format="yyyy-MM-dd" @change="changeFun4">
									</el-date-picker>
								</div>
							</div>
						</div>
					</div>
					<div id="ec18" style="width: 470px; height: 220px"></div>
				</div>
			</div>
			<div class="line22" style="margin-right: 0; height: 395px">
				<div class="hea">
					<img class="img" src="@/assets/images/line3.png" alt="" />
					<p>z轴负载</p>
				</div>
				<div class="zhu">
					<div class="tit">
						<p></p>
						<div class="mul">
							<div @click="zzhuT = 1" :class="['mli', zzhuT == 1 ? 'mac' : '']">
								本周
							</div>
							<div @click="zzhuT = 2" :class="['mli', zzhuT == 2 ? 'mac' : '']">
								本月
							</div>
							<div @click="zzhuT = 3" :class="['mli', zzhuT == 3 ? 'mac' : '']">
								自定义
								<div class="datebox">
									<el-date-picker v-model="value13" type="daterange" range-separator="至" start-placeholder="开始日期"
										end-placeholder="结束日期" format="yyyy - MM - dd" value-format="yyyy-MM-dd" @change="changeFun5">
									</el-date-picker>
								</div>
							</div>
						</div>
					</div>
					<div id="ec20" style="width: 470px; height: 220px"></div>
				</div>
			</div>
		</div> -->
		<div class="line2" style="margin-top: 16px; height: 260px">
			<div class="line22" style="height: 260px">
				<div class="hea">
					<img class="img" src="@/assets/images/line3.png" alt="" />
					<p>刀具信息</p>
				</div>
				<div class="body body1">
					<div class="b1">
						<p>当前T号：</p>
						<p class="b2">
							{{ dataObj && dataObj.current_t_no ? dataObj.current_t_no : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>设定寿命：</p>
						<p class="b2">
							{{ dataObj && dataObj.setLife ? dataObj.setLife : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>剩余寿命：</p>
						<p class="b2">
							{{ dataObj && dataObj.restLife ? dataObj.restLife : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>使用寿命：</p>
						<p class="b2">
							{{ dataObj && dataObj.usedLife ? dataObj.usedLife : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>报警寿命：</p>
						<p class="b2">
							{{ dataObj && dataObj.alarmLife ? dataObj.alarmLife : "-" }}
						</p>
					</div>
					<div class="b1">
						<p>刀具补偿：</p>
						<p class="b2">
							{{
                dataObj && dataObj.toolCompensation
                  ? dataObj.toolCompensation
                  : "-"
              }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from "echarts";
	export default {
		props: ["id", "name", "bnum"],
		data() {
			return {
				zzhuh: [],
				zncObj: {},
				value13: "",
				zzhuST: "",
				zzhuET: "",
				zzhuT: 1,
				zzhux: ["1月", "2月", "3月"], //主轴x
				zzhuy: [20, 25, 10], //主轴y
				yzhuh: [],
				yncObj: {},
				value12: "",
				yzhuST: "",
				yzhuET: "",
				yzhuT: 1,
				yzhux: ["1月", "2月", "3月"], //主轴x
				yzhuy: [20, 30, 10], //主轴y
				yzhuh: [],
				xncObj: {},
				value11: "",
				xzhuST: "2024-04-01",
				xzhuET: "2024-04-07",
				xzhuT: 1,
				xzhux: [], //主轴x
				xzhuy: [], //主轴y
				value10: "",
				zhuST: "",
				zhuET: "",
				zhuT: 1,
				zhuh: [],
				zhux: ["1月", "2月", "3月"], //主轴x
				zhuy: [20, 5, 10], //主轴y
				chartObj9: {},
				value9: "",
				alarmST: "",
				alarmET: "",
				alarmT: 1,
				xAlarm: ["1月", "2月", "3月"], //x报警次数
				yAlarm: [20, 6, 10], //报警次数y
				value8: "",
				openST: "2024-04-01",
				openET: "2024-04-07",
				openT: 1,
				xOpen: [], //开机时间x
				yOpen: [], //开机时间y
				dataObj: {},
				chartObj: {},
				chartObj1: {},
				alarmObj: [],
				ncObj: {},
			};
		},
		watch: {
			openT(val) {
				if (val == 1) {
					// 获取本周开始结束时间
					this.getWeekDay(1);
					// 获取图表数据
					this.getDataChart();
				} else if (val == 2) {
					// 获取本月开始结束时间
					this.getMonthDay(1);
					// 获取图表数据
					this.getDataChart();
				} else if (val == 3) {
					this.changeFun();
				}
			},
			alarmT(val) {
				if (val == 1) {
					// 获取本周开始结束时间
					this.getWeekDay(2);
					// 获取图表数据
					this.getAlarmDataChart();
				} else if (val == 2) {
					// 获取本月开始结束时间
					this.getMonthDay(2);
					// 获取图表数据
					this.getAlarmDataChart();
				} else if (val == 3) {
					this.changeFun1();
				}
			},
			zhuT(val) {
				if (val == 1) {
					// 获取本周开始结束时间
					this.getWeekDay(3);
					// 获取图表数据
					this.getNCData();
				} else if (val == 2) {
					// 获取本月开始结束时间
					this.getMonthDay(3);
					// 获取图表数据
					this.getNCData();
				} else if (val == 3) {
					this.changeFun2();
				}
			},
			xzhuT(val) {
				if (val == 1) {
					// 获取本周开始结束时间
					this.getWeekDay(4);
					// 获取图表数据
					this.getXNCData();
				} else if (val == 2) {
					// 获取本月开始结束时间
					this.getMonthDay(4);
					// 获取图表数据
					this.getXNCData();
				} else if (val == 3) {
					this.changeFun3();
				}
			},
			yzhuT(val) {
				if (val == 1) {
					// 获取本周开始结束时间
					this.getWeekDay(5);
					// 获取图表数据
					this.getYNCData();
				} else if (val == 2) {
					// 获取本月开始结束时间
					this.getMonthDay(5);
					// 获取图表数据
					this.getYNCData();
				} else if (val == 3) {
					this.changeFun4();
				}
			},
			zzhuT(val) {
				if (val == 1) {
					// 获取本周开始结束时间
					this.getWeekDay(6);
					// 获取图表数据
					this.getZNCData();
				} else if (val == 2) {
					// 获取本月开始结束时间
					this.getMonthDay(6);
					// 获取图表数据
					this.getZNCData();
				} else if (val == 3) {
					this.changeFun5();
				}
			},
		},
		mounted() {
			this.setEc1();
			this.setEc2();
			this.setEc3();
			this.setEc4();
			this.setEc5();
			this.setEc6();
		},
		created() {
			if (this.name == "seix") {
				this.id = 86040222;
				// 获取本周开始结束时间
				// this.getWeekDay(1);
				// this.getWeekDay(2);
				// this.getWeekDay(3);
				// this.getWeekDay(4);
				// this.getWeekDay(5);
				// this.getWeekDay(6);

				// 获取最新数据
				this.getNewData();
				// 获取图表数据
				this.getDataChart();
				// 获取报警记录数据
				this.getNCData();
				this.getXNCData();
				// this.getYNCData();
				// this.getZNCData();

				this.getAlarmDataChart();
				// 获取图表数据
				this.getDataChart1();
				this.getRaing();
			}
		},
		methods: {
			getRaing() {
				this.$API
					.iotDataAlarm({
						deviceId: 'ufx00002'
					})
					.then((res) => {
						if (res.code == 1) {
							this.alarmObj = this.bnum == 'B3G140245' ? res.data.records : [];
						} else {
							this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 时间改变函数
			changeFun5() {
				if (this.value13 && this.value13.length) {
					this.zzhuST = this.value13[0];
					this.zzhuET = this.value13[1];
				} else {
					this.zzhuST = "";
					this.zzhuET = "";
				}
				// 获取图表数据
				this.getZNCData();
			},
			// 时间改变函数
			changeFun4() {
				if (this.value12 && this.value12.length) {
					this.yzhuST = this.value12[0];
					this.yzhuET = this.value12[1];
				} else {
					this.yzhuST = "";
					this.yzhuET = "";
				}
				// 获取图表数据
				this.getYNCData();
			},
			// 时间改变函数
			changeFun3() {
				if (this.value11 && this.value11.length) {
					this.xzhuST = this.value11[0];
					this.xzhuET = this.value11[1];
				} else {
					this.xzhuST = "";
					this.xzhuET = "";
				}
				// 获取图表数据
				this.getXNCData();
			},
			// 时间改变函数
			changeFun2() {
				if (this.value10 && this.value10.length) {
					this.zhuST = this.value10[0];
					this.zhuET = this.value10[1];
				} else {
					this.zhuST = "";
					this.zhuET = "";
				}
				// 获取图表数据
				this.getNCData();
			},
			// 时间改变函数
			changeFun1() {
				if (this.value9 && this.value9.length) {
					this.alarmST = this.value9[0];
					this.alarmET = this.value9[1];
				} else {
					this.alarmST = "";
					this.alarmET = "";
				}
				// 获取图表数据
				this.getAlarmDataChart();
			},
			// 时间改变函数
			changeFun() {
				if (this.value8 && this.value8.length) {
					this.openST = this.value8[0];
					this.openET = this.value8[1];
				} else {
					this.openST = "";
					this.openET = "";
				}
				// 获取图表数据
				this.getDataChart();
			},
			// 获取本yue开始结束时间
			getMonthDay(type) {
				// 获取当前日期
				var currentDate = new Date();

				// 获取本月的第一天
				var firstDay = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					1
				);

				// 获取下个月的第一天，然后减去一天得到本月的最后一天
				var nextMonth = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth() + 1,
					1
				);
				var lastDay = new Date(nextMonth.setDate(nextMonth.getDate() - 1));

				// 格式化日期为年-月-日的形式
				if (type == 1) {
					this.openST =
						firstDay.getFullYear() +
						"-" +
						(firstDay.getMonth() + 1) +
						"-" +
						firstDay.getDate();
					this.openET =
						lastDay.getFullYear() +
						"-" +
						(lastDay.getMonth() + 1) +
						"-" +
						lastDay.getDate();
				} else if (type == 2) {
					this.alarmST =
						firstDay.getFullYear() +
						"-" +
						(firstDay.getMonth() + 1) +
						"-" +
						firstDay.getDate();
					this.alarmET =
						lastDay.getFullYear() +
						"-" +
						(lastDay.getMonth() + 1) +
						"-" +
						lastDay.getDate();
				} else if (type == 3) {
					this.zhuST =
						firstDay.getFullYear() +
						"-" +
						(firstDay.getMonth() + 1) +
						"-" +
						firstDay.getDate();
					this.zhuET =
						lastDay.getFullYear() +
						"-" +
						(lastDay.getMonth() + 1) +
						"-" +
						lastDay.getDate();
				} else if (type == 4) {
					this.xzhuST =
						firstDay.getFullYear() +
						"-" +
						(firstDay.getMonth() + 1) +
						"-" +
						firstDay.getDate();
					this.xzhuET =
						lastDay.getFullYear() +
						"-" +
						(lastDay.getMonth() + 1) +
						"-" +
						lastDay.getDate();
				} else if (type == 5) {
					this.yzhuST =
						firstDay.getFullYear() +
						"-" +
						(firstDay.getMonth() + 1) +
						"-" +
						firstDay.getDate();
					this.yzhuET =
						lastDay.getFullYear() +
						"-" +
						(lastDay.getMonth() + 1) +
						"-" +
						lastDay.getDate();
				} else if (type == 6) {
					this.zzhuST =
						firstDay.getFullYear() +
						"-" +
						(firstDay.getMonth() + 1) +
						"-" +
						firstDay.getDate();
					this.zzhuET =
						lastDay.getFullYear() +
						"-" +
						(lastDay.getMonth() + 1) +
						"-" +
						lastDay.getDate();
				}
			},
			// 获取本周开始结束时间
			getWeekDay(type) {
				// 获取当前日期
				var currentDate = new Date();

				// 获取本周的第一天（周日为一周的开始）
				var firstDay = new Date(
					currentDate.setDate(currentDate.getDate() - currentDate.getDay())
				);

				// 获取本周的最后一天（周六）
				var lastDay = new Date(firstDay);
				lastDay.setDate(lastDay.getDate() + 6);

				// 格式化日期为年-月-日的形式
				if (type == 1) {
					this.openST =
						firstDay.getFullYear() +
						"-" +
						(firstDay.getMonth() + 1) +
						"-" +
						firstDay.getDate();
					this.openET =
						lastDay.getFullYear() +
						"-" +
						(lastDay.getMonth() + 1) +
						"-" +
						lastDay.getDate();
				} else if (type == 2) {
					this.alarmST =
						firstDay.getFullYear() +
						"-" +
						(firstDay.getMonth() + 1) +
						"-" +
						firstDay.getDate();
					this.alarmET =
						lastDay.getFullYear() +
						"-" +
						(lastDay.getMonth() + 1) +
						"-" +
						lastDay.getDate();
				} else if (type == 3) {
					this.zhuST =
						firstDay.getFullYear() +
						"-" +
						(firstDay.getMonth() + 1) +
						"-" +
						firstDay.getDate();
					this.zhuET =
						lastDay.getFullYear() +
						"-" +
						(lastDay.getMonth() + 1) +
						"-" +
						lastDay.getDate();
				} else if (type == 4) {
					this.xzhuST =
						firstDay.getFullYear() +
						"-" +
						(firstDay.getMonth() + 1) +
						"-" +
						firstDay.getDate();
					this.xzhuET =
						lastDay.getFullYear() +
						"-" +
						(lastDay.getMonth() + 1) +
						"-" +
						lastDay.getDate();
				} else if (type == 5) {
					this.yzhuST =
						firstDay.getFullYear() +
						"-" +
						(firstDay.getMonth() + 1) +
						"-" +
						firstDay.getDate();
					this.yzhuET =
						lastDay.getFullYear() +
						"-" +
						(lastDay.getMonth() + 1) +
						"-" +
						lastDay.getDate();
				} else if (type == 6) {
					this.zzhuST =
						firstDay.getFullYear() +
						"-" +
						(firstDay.getMonth() + 1) +
						"-" +
						firstDay.getDate();
					this.zzhuET =
						lastDay.getFullYear() +
						"-" +
						(lastDay.getMonth() + 1) +
						"-" +
						lastDay.getDate();
				}
			},
			// 获取报警记录数据
			getZNCData() {
				this.$API
					.iotDataNC({
						deviceId: this.id,
						axisType: 4,
						startTime: this.zzhuST,
						endTime: this.zzhuET,
					})
					.then((res) => {
						if (res.code == 1) {
							this.zzhux = [];
							this.zzhuy = [];
							this.zncObj = res.data;
							this.zncObj.forEach((item) => {
								this.zzhux.push(item.rangeType);
								this.zzhuy.push(item.minute);
								this.zzhuh.push(item.hour);

								this.zzhux = [...this.zzhux];
								this.zzhuy = [...this.zzhuy];
								this.zzhuh = [...this.zzhuh];
							});
							this.setEc6();
						} else {
							this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 获取报警记录数据
			getYNCData() {
				this.$API
					.iotDataNC({
						deviceId: this.id,
						axisType: 3,
						startTime: this.yzhuST,
						endTime: this.yzhuET,
					})
					.then((res) => {
						if (res.code == 1) {
							this.yzhux = [];
							this.yzhuy = [];
							this.yncObj = res.data;
							this.yncObj.forEach((item) => {
								this.yzhux.push(item.rangeType);
								this.yzhuy.push(item.minute);
								this.yzhuh.push(item.hour);

								this.yzhux = [...this.yzhux];
								this.yzhuy = [...this.yzhuy];
								this.yzhuh = [...this.yzhuh];
							});
							this.setEc5();
						} else {
							this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 获取报警记录数据
			getXNCData() {
				this.$API
					.iotDataNC({
						deviceId: 'ufx00002',
						axisType: 2,
						startTime: this.xzhuST,
						endTime: this.xzhuET,
					})
					.then((res) => {
						if (res.code == 1) {
							// this.xzhux = [];
							// this.xzhuy = [];
							// this.xncObj = this.bnum == 'B3G140245' ? res.result.records : [];
							this.xzhux = this.bnum == 'B3G140245' ? ['0-60%', '60%-80%', '80%-100%', '100%以上'] : []
							this.xzhuy = this.bnum == 'B3G140245' ? [380, 96, 0, 0] : []
							// this.xncObj.forEach((item) => {
							// 	this.xzhux.push(item.rangeType);
							// 	this.xzhuy.push(item.minute);
							// 	this.xzhuh.push(item.hour);

							// 	this.xzhux = [...this.xzhux];
							// 	this.xzhuy = [...this.xzhuy];
							// 	this.xzhuh = [...this.xzhuh];
							// });
							this.setEc4();
						} else {
							this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 获取报警记录数据
			getNCData() {
				this.$API
					.iotDataNC({
						deviceId: this.id,
						axisType: 1,
						startTime: this.zhuST,
						endTime: this.zhuET,
					})
					.then((res) => {
						if (res.code == 1) {
							this.zhux = [];
							this.zhuy = [];
							this.ncObj = res.data;
							this.zhux = this.bnum == 'B3G140245' ? ['0-60%', '60%-80%', '80%-100%', '100%以上'] : 0
							this.zhuy = this.bnum == 'B3G140245' ? [380, 96, 0, 0] : 0
							// this.ncObj.forEach((item) => {
							// 	this.zhux.push(item.rangeType);
							// 	this.zhuy.push(item.minute);
							// 	this.zhuh.push(item.hour);

							// 	this.zhux = [...this.zhux];
							// 	this.zhuy = [...this.zhuy];
							// 	this.zhuh = [...this.zhuh];
							// });
							this.setEc3();
						} else {
							this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 获取图表数据
			getDataChart1() {
				this.$API
					.iotDataChart1({
						deviceId: this.id
					})
					.then((res) => {
						if (res.code == 1) {
							this.chartObj1 = res.data.records;
						} else {
							this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 获取图表数据报警
			getAlarmDataChart() {
				this.$API
					.iotDataChart({
						deviceId: 'ufx00002',
						type: 2,
						startTime: this.alarmST,
						endTime: this.alarmET,
					})
					.then((res) => {
						if (res.code == 1) {
							this.xAlarm = [];
							this.yAlarm = [];
							this.chartObj9 = res.data.records;
							this.xAlarm = this.bnum == 'B3G140245' ? ['2024-04-01', '2024-04-02', '2024-04-03', '2024-04-04',
								'2024-04-05', '2024-04-06',
								'2024-04-07'
							] : 0
							this.yAlarm = this.bnum == 'B3G140245' ? [2, 2, 4, 5, 3, 4, 4] : 0
							// this.chartObj9.forEach((item) => {
							// 	this.xAlarm.push(item.time);
							// 	this.yAlarm.push(item.alarm);
							// 	this.xAlarm = [...this.xAlarm];
							// 	this.yAlarm = [...this.yAlarm];
							// });
							this.setEc2();
						} else {
							this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 获取图表数据
			getDataChart() {
				this.$API
					.iotDataChart({
						deviceId: 'ufx00002',
						type: 1,
						startTime: this.openST,
						endTime: this.openET,
					})
					.then((res) => {
						if (res.code == 1) {
							// this.xOpen = [];
							// this.yOpen = [];
							console.log('开机时间', res);
							this.chartObj = res.data.records;
							console.log('开机时间', this.chartObj);
							this.xOpen = this.bnum == 'B3G140245' ? this.chartObj.map(item => {
								return item.time
							}) : 0
							this.yOpen = this.bnum == 'B3G140245' ? this.chartObj.map(item => {
								return item.powerTime
							}) : 0
							// this.chartObj.forEach((item) => {
							// 	this.xOpen.push(item.time);
							// 	this.yOpen.push(item.powerTime);
							// 	this.xOpen = [...this.xOpen];
							// 	this.yOpen = [...this.yOpen];
							// });
							this.setEc1();
						} else {
							this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 获取最新数据
			getNewData() {
				this.$API
					.iotNewData({
						deviceId: 'ufx00002'
					})
					.then((res) => {
						if (res.code == 1) {
							this.dataObj = this.bnum == 'B3G140245' ? res.data : {};
						} else {
							this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			setEc6() {
				var chartDom = document.getElementById("ec20");
				var myChart = echarts.init(chartDom);
				var option;
				var xzhuh = this.zzhuh;
				var xzhuxshow = this.zzhux;

				option = {
					xAxis: {
						type: "category",
						// boundaryGap: false,
						data: this.zzhux,
					},
					yAxis: {
						type: "value",
					},
					tooltip: {
						trigger: "axis",
						formatter: function(params) {
							var dataIndex = params[0].dataIndex;
							return xzhuxshow[dataIndex] + "数据：" + xzhuh[dataIndex];
						},
					},
					grid: {
						top: "10px",
						left: "30px",
						right: "10px",
						bottom: "20px",
					},
					series: [{
						data: this.zzhuy,
						type: "bar",
						lineStyle: {
							color: "#1577FC", // 设置线的颜色为红色
						},
						itemStyle: {
							//设置端点颜色
							normal: {
								color: "#1577FC", // 设置线条上点的颜色（和图例的颜色）
							},
						},
					}, ],
				};
				option && myChart.setOption(option);
			},
			setEc5() {
				var chartDom = document.getElementById("ec18");
				var myChart = echarts.init(chartDom);
				var option;
				var xzhuh = this.yzhuh;
				var xzhuxshow = this.yzhux;

				option = {
					xAxis: {
						type: "category",
						// boundaryGap: false,
						data: this.yzhux,
					},
					yAxis: {
						type: "value",
					},
					tooltip: {
						trigger: "axis",
						formatter: function(params) {
							var dataIndex = params[0].dataIndex;
							return xzhuxshow[dataIndex] + "数据：" + xzhuh[dataIndex];
						},
					},
					grid: {
						top: "10px",
						left: "30px",
						right: "10px",
						bottom: "20px",
					},
					series: [{
						data: this.yzhuy,
						type: "bar",
						lineStyle: {
							color: "#1577FC", // 设置线的颜色为红色
						},
						itemStyle: {
							//设置端点颜色
							normal: {
								color: "#1577FC", // 设置线条上点的颜色（和图例的颜色）
							},
						},
					}, ],
				};
				option && myChart.setOption(option);
			},
			setEc4() {
				var chartDom = document.getElementById("ec13");
				var myChart = echarts.init(chartDom);
				var option;
				var xzhuh = this.xzhuh;
				var xzhuxshow = this.xzhux;

				option = {
					xAxis: {
						type: "category",
						// boundaryGap: false,
						data: this.xzhux,
					},
					yAxis: {
						type: "value",
					},
					tooltip: {
						trigger: "axis",
						// formatter: function(params) {
						// 	var dataIndex = params[0].dataIndex;
						// 	return xzhuxshow[dataIndex] + "数据：" + xzhuh[dataIndex];
						// },
					},
					grid: {
						top: "10px",
						left: "30px",
						right: "10px",
						bottom: "20px",
					},
					series: [{
						data: this.xzhuy,
						type: "bar",
						lineStyle: {
							color: "#1577FC", // 设置线的颜色为红色
						},
						itemStyle: {
							//设置端点颜色
							normal: {
								color: "#1577FC", // 设置线条上点的颜色（和图例的颜色）
							},
						},
					}, ],
				};
				option && myChart.setOption(option);
			},
			setEc3() {
				var chartDom = document.getElementById("ec12");
				var myChart = echarts.init(chartDom);
				var option;
				var zhuh = this.zhuh;
				var zhuxshow = this.zhux;

				option = {
					xAxis: {
						type: "category",
						// boundaryGap: false,
						data: this.zhux,
					},
					yAxis: {
						type: "value",
					},
					tooltip: {
						trigger: "axis",
						// formatter: function(params) {
						// 	var dataIndex = params[0].dataIndex;
						// 	return zhuxshow[dataIndex] + "数据：" + zhuh[dataIndex];
						// },
					},
					// zhuh
					grid: {
						top: "10px",
						left: "30px",
						right: "10px",
						bottom: "20px",
					},
					series: [{
						data: this.zhuy,
						type: "bar",
						lineStyle: {
							color: "#1577FC", // 设置线的颜色为红色
						},
						itemStyle: {
							//设置端点颜色
							normal: {
								color: "#1577FC", // 设置线条上点的颜色（和图例的颜色）
							},
						},
					}, ],
				};
				option && myChart.setOption(option);
			},
			setEc2() {
				var chartDom = document.getElementById("ec11");
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: this.xAlarm,
					},
					yAxis: {
						type: "value",
					},
					grid: {
						top: "10px",
						left: "30px",
						right: "10px",
						bottom: "20px",
					},
					series: [{
						data: this.yAlarm,
						type: "line",
						lineStyle: {
							color: "#ed1b23", // 设置线的颜色为红色
						},
						itemStyle: {
							//设置端点颜色
							normal: {
								color: "#ed1b23", // 设置线条上点的颜色（和图例的颜色）
							},
						},
					}, ],
				};
				option && myChart.setOption(option);
			},
			setEc1() {
				var chartDom = document.getElementById("ec10");
				var myChart = echarts.init(chartDom);
				var option;
				console.log(this.xOpen, this.yOpen, this.bnum)
				option = {
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: this.xOpen,
						// axisLabel: {
						//   interval: 0,
						//   rotate: 40,
						// },
					},
					yAxis: {
						type: "value",
					},
					grid: {
						top: "10px",
						left: "30px",
						right: "10px",
						bottom: "20px",
					},

					series: [{
						data: this.yOpen,
						type: "line",
						lineStyle: {
							color: "#1577FC", // 设置线的颜色为红色
						},
						itemStyle: {
							//设置端点颜色
							normal: {
								color: "#1577FC", // 设置线条上点的颜色（和图例的颜色）
							},
						},
					}, ],
				};
				option && myChart.setOption(option);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.deviceparam {
		.box {
			box-sizing: border-box;
			width: 100%;
			// height: 608px;
			background: #ffffff;
			border-radius: 8px 8px 8px 8px;
			padding: 24px;
			margin-bottom: 16px;
		}

		.hea {
			font-size: 16px;
			color: #333333;
			display: flex;
			align-items: center;
			margin-bottom: 16px;
		}

		.img {
			width: 4px;
			height: 14px;
			margin-right: 10px;
		}

		.item {
			display: flex;
		}

		.line {
			border: 1px solid #dedede;
			box-sizing: border-box;
			padding: 20px;
		}

		.line11 {
			width: 304px;
			height: 266px;
			margin-right: 12px;
		}

		.datebox {
			width: 0;
			height: 0;

			.el-date-editor {
				opacity: 0 !important;
				width: 40px !important;
				height: 30px !important;
				position: relative !important;
				top: -20px !important;
			}
		}

		.line22 {
			width: 458px;
			height: 346px;
		}

		.tit {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 26px;
			font-size: 14px;
			color: #333333;
			font-weight: 500;
		}

		.mul {
			display: flex;
			align-items: center;
			cursor: pointer;
		}

		.mli {
			font-weight: 400;
			padding: 5px 10px;
			background: #edefef;
			font-size: 12px;
			color: #595959;
			margin-left: 5px;
			border-radius: 2px;
		}

		.mac {
			background: #d0e4ff;
			color: #1577fc;
		}

		.redmac {
			background: #fde7e8;
			color: #ed1b23;
		}

		.list1 {
			width: 407px;
			height: 180px;
			overflow: hidden;
		}

		.lista {
			width: 427px;
			height: 180px;
			overflow-x: auto;
			overflow-y: scroll;
		}

		.lis {
			display: flex;
			width: 388px;
			height: 36px;
			background: #f4f4f4;
			display: flex;
			align-items: center;
		}

		.lis1 {
			display: flex;
			width: 388px;
			height: 36px;
			background: #f9f9f9;
			display: flex;
			align-items: center;
		}

		.lista .lis1:nth-child(2n) {
			background-color: #ffffff;
		}

		.left {
			width: 270px;
			height: 36px;
			line-height: 36px;
			text-align: center;
			border-right: 1px solid #eeeeee;
		}

		.right {
			flex: 1;
			text-align: center;
		}

		.line2 {
			display: flex;
		}

		.line22 {
			width: 550px;
			height: 325px;
			background: #ffffff;
			border-radius: 4px 4px 4px 4px;
			margin-right: 16px;
			box-sizing: border-box;
			padding: 20px;
		}

		.body {
			width: 510px;
			height: 240px;
			box-sizing: border-box;
			padding: 20px;
			background: #f4f4f4;
			border-radius: 4px 4px 4px 4px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			font-size: 14px;
			color: #666666;
		}

		.body1 {
			height: 180px;
		}

		.b1 {
			width: 200px;
			height: 40px;
		}

		.b2 {
			font-size: 14px;
			color: #333333;
			margin-top: 7px;
		}

		.zhu {
			width: 512px;
			height: 309px;
			background: rgba(255, 255, 255, 0);
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #dedede;
			box-sizing: border-box;
			padding: 20px;
		}
	}
</style>