<template>
  <div class="device-contaner">
    <el-row class="device-box">
      <el-row class="contaner">
        <!-- 我的设备列表 start -->
        <el-row class="device-list">
          <!-- 选择公司 -->
          <el-row class="check" type="flex" align="middle">
            <el-row class="company">
              <span class="fs14 c6 name">公司名称</span>
              <el-select v-model="cityValue" placeholder="请选择">
                <el-option
                  v-for="item in cities"
                  :key="item.value"
                  :value="item.value"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.value
                  }}</span>
                </el-option>
              </el-select>
            </el-row>
            <!-- 选择状态 -->
            <el-row class="status">
              <span class="fs14 c6 statu-name">设备状态</span>
              <el-select v-model="stateValue" placeholder="请选择">
                <el-option
                  v-for="item in states"
                  :key="item.text"
                  :label="item.text"
                  :value="item.value"
                >
                  <!-- <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.text
                  }}</span> -->
                </el-option>
              </el-select>
            </el-row>
            <!-- 按钮 -->
            <el-button class="search" @click="seachHandle">搜索</el-button>
            <div class="action">
              <el-button class="item" @click="$router.push({name: 'add_device'})">
                添加设备
              </el-button>
            </div>
          </el-row>
        </el-row>

        <el-row class="check-device">
          <el-tag class="tag" type="info">全部（{{ totalNum }}台）</el-tag>
          <el-tag class="tag" type="success">启用（{{ startNum }}台）</el-tag>
          <el-tag class="tag" type="warning">维修（{{ repairNum }}台）</el-tag>
          <el-tag class="tag" type="danger">停用（{{ stopNum }}台）</el-tag>
        </el-row>

        <!-- 表格展示 start -->
        <el-row class="table-box">
          <el-table
            border
            :data="tableData"
            style="width: 100%"
            :key="Math.random()"
          >
            <!-- <el-table-column
              prop="name"
              label="发布订阅关系名称"
              align="center"
            >
              <template slot-scope="{ row }">
                {{ row.name || "暂⽆发布订阅关系" }}
              </template>
            </el-table-column> -->
            <el-table-column
              prop="frontIndex"
              label="序号"
              min-width="50"
              align="center"
              :key="Math.random()"
            >
            </el-table-column>
            <el-table-column
              prop="num"
              label="编号"
              min-width="100"
              align="center"
              :key="Math.random()"
            >
              <template slot-scope="{ row }">{{ row.num || "-" }}</template>
            </el-table-column>
            <el-table-column
              prop="type"
              label="设备类型"
              min-width="120"
              align="center"
              :key="Math.random()"
            >
              <template slot-scope="{ row }">{{
                `${row.deviceTypeName}-${row.deviceTypeParentName}` || "-"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="make"
              label="设备品牌"
              min-width="120"
              align="center"
              :key="Math.random()"
            >
              <template slot-scope="{ row }">{{ row.make || "-" }}</template>
            </el-table-column>
            <el-table-column
              prop="modal"
              label="设备型号"
              min-width="120"
              align="center"
              :key="Math.random()"
            >
              <template slot-scope="{ row }">{{ row.modal || "-" }}</template>
            </el-table-column>
            <el-table-column
              prop="company"
              label="所属公司"
              min-width="120"
              align="center"
              :key="Math.random()"
            >
              <template slot-scope="{ row }">{{ row.company || "-" }}</template>
            </el-table-column>
            <el-table-column
              prop="date"
              label="出厂日期"
              min-width="100"
              align="center"
              :key="Math.random()"
            >
              <template slot-scope="{ row }">{{ row.date || "-" }}</template>
            </el-table-column>
            <el-table-column
              prop="repair"
              label="预约维保数量"
              min-width="120"
              align="center"
              :key="Math.random()"
            >
              <template slot-scope="{ row }">{{ row.preRepairNum }}</template>
            </el-table-column>
            <el-table-column
              prop="device"
              label="设备状态"
              min-width="100"
              align="center"
              :key="Math.random()"
            >
              <template slot-scope="{ row }">{{ row.device || "-" }}</template>
              <template slot-scope="scope">
                <!-- 维修中 -->
                <span
                  v-if="scope.row.status == 2"
                  :class="{ green: scope.row.status == 2 }"
                >
                  维修中
                </span>
                <!-- 维修中 -->
                <span
                  v-if="scope.row.status == 0"
                  :class="{ blue: scope.row.status == 0 }"
                >
                  启用中
                </span>
                <!-- 维修中 -->
                <span
                  v-if="scope.row.status == 1"
                  :class="{ red: scope.row.status == 1 }"
                >
                  禁用中
                </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="status"
              label="状态"
              min-width="440"
              :key="Math.random()"
            >
              <template slot-scope="scope">
                <!-- 报修 -->
                <span
                  @click="handleRepair(scope.$index, scope.row)"
                  class="f14 see p b"
                >
                  <router-link
                    :to="{
                      name: 'repairform',
                      query: {
                        id: scope.row.id,
                        companyId: scope.row.companyId,
                      },
                    }"
                    >报修</router-link
                  >
                </span>
                <span class="line"></span>
                <!-- 维保记录 -->
                <span
                  @click="handleRcord(scope.$index, scope.row)"
                  class="f14 see p b"
                >
                  <router-link
                    :to="{ name: 'repairrecord', query: { id: scope.row.id } }"
                    >维保记录</router-link
                  >
                </span>
                <span class="line"></span>
                <!-- 维保提醒 -->
                <span
                  @click="handleMind(scope.$index, scope.row)"
                  class="f14 see p b"
                >
                  <router-link
                    :to="{ name: 'repairremind', query: { id: scope.row.id } }"
                    >维保提醒</router-link
                  >
                </span>
                <span class="line"></span>
                <!-- 设备资料 -->
                <span
                  @click="handleData(scope.$index, scope.row)"
                  class="f14 see p b"
                >
                  <router-link
                    :to="{ name: 'adddata', query: { id: scope.row.id } }"
                    >设备资料</router-link
                  >
                </span>
                <span class="line"></span>
                <!-- 编辑 -->
                <span
                  class="f14 see p b"
                  @click="handleEdit(scope.$index, scope.row)"
                >
                  <!-- <span>编辑</span> -->
                  <router-link
                    :to="{ name: 'editdevice', query: { id: scope.row.id } }"
                    >编辑</router-link
                  >
                </span>
                <span class="line"></span>
                <!-- 故障分析 -->
                <span
                  @click="handleData(scope.$index, scope.row)"
                  class="f14 see p b"
                >
                  <router-link
                    :to="{ name: 'faultasync', query: { id: scope.row.id } }"
                    >故障分析</router-link
                  >
                </span>
                <span class="line"></span>
                <!-- IOT数据 -->
                <span @click="seeIot(scope.row)" class="f14 see p b">
                  IOT数据
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <!-- 表格展示 end -->
        <!-- 分页开始 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="listTotal"
          :page-size="pageSize"
          :current-page="page"
          @current-change="pageChange"
          @prev-click="prevChange"
          @next-click="nextChange"
        >
        </el-pagination>
        <!-- 分页结束 -->
      </el-row>
    </el-row>
    <!-- 右侧保修 客服 -->
    <tell-us bottom="266px"></tell-us>
    <!-- 弹窗结束 -->
    <div class="iot">
      <!-- 弹窗 start -->
      <el-dialog
        :visible.sync="assderVisible"
        width="1200px"
        :show-close="false"
        class="assderTc"
      >
        <span slot="title" class="title">
          <p class="flex">
            <img src="@/assets/images/line3.png" class="line2" />
            <span>IOT数据</span>
          </p>

          <div>
            <!-- <el-button class="subBtn" type="primary">提交</el-button> -->
            <el-button plain @click="assderVisible = false">关闭</el-button>
          </div>
        </span>

        <div class="con">
          <!-- @tab-click="handleClick" -->
          <div class="hea">
            <el-tabs
              :key="activeName"
              v-model="activeName"
              @tab-click="getclick"
            >
              <el-tab-pane label="报警统计" name="first">
                <AlarmCount
                  ref="AlarmCount"
                  :name="activeName"
                  :bnum="bnum"
                  :id="iotId"
                ></AlarmCount>
              </el-tab-pane>
              <el-tab-pane label="历史趋势" name="second">
                <History
                  ref="History1"
                  :name="activeName"
                  :bnum="bnum"
                  :id="iotId"
                ></History>
              </el-tab-pane>
              <el-tab-pane label="碰撞缓冲报警" name="third">
                <HitPolice
                  :name="activeName"
                  :bnum="bnum"
                  :id="iotId"
                ></HitPolice>
              </el-tab-pane>
              <el-tab-pane label="预维报警" name="fourth">
                <PrePolice
                  :name="activeName"
                  :bnum="bnum"
                  :id="iotId"
                ></PrePolice>
              </el-tab-pane>
              <el-tab-pane label="报警记录" name="five">
                <PoliceRecord
                  :name="activeName"
                  :bnum="bnum"
                  :id="iotId"
                ></PoliceRecord>
              </el-tab-pane>
              <el-tab-pane label="设备参数" name="seix">
                <DeviceParam
                  ref="DeviceParam"
                  :bnum="bnum"
                  :name="activeName"
                  :id="iotId"
                ></DeviceParam>
              </el-tab-pane>
              <el-tab-pane label="振动数据" name="eleven">
                <MoveData
                  ref="MoveData"
                  :bnum="bnum"
                  :name="activeName"
                  :id="iotId"
                ></MoveData>
              </el-tab-pane>
              <el-tab-pane label="振动报警" name="nine">
                <MovePolice
                  :bnum="bnum"
                  :name="activeName"
                  :id="iotId"
                ></MovePolice>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </el-dialog>
      <!-- 弹窗结束 -->
    </div>
  </div>
</template>

<script>
import AlarmCount from "@/components/iot/AlarmCount.vue";
import History from "@/components/iot/History.vue";
import HitPolice from "@/components/iot/HitPolice.vue";
import PrePolice from "@/components/iot/PrePolice.vue";
import PoliceRecord from "@/components/iot/PoliceRecord.vue";
import DeviceParam from "@/components/iot/DeviceParam.vue";
import MoveData from "@/components/iot/MoveData.vue";
import MovePolice from "@/components/iot/MovePolice.vue";

import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
import TellUs from "@/components/TellUs.vue";
import axios from "axios";
export default {
  name: "YoufujichuangDevice",
  components: {
    MovePolice,
    AlarmCount,
    History,
    HitPolice,
    PrePolice,
    PoliceRecord,
    MoveData,
    DeviceParam,
    Header,
    Nav,
    Footer,
    TellUs,
  },
  data() {
    return {
      iotId: "", //iot数据id
      activeName: "first",
      // 弹窗结束
      assderVisible: false, //客服弹窗
      cityValue: "",
      cities: [], //city选择框值
      stateValue: "",
      states: [], //status选择框值
      totalNum: 0, //设备总数
      repairNum: 0, //维修数量
      startNum: 0, //启用数量
      stopNum: 0, //停用数量
      tableData: [], //表格数据
      listTotal: 0, //列表总数据
      pageSize: 5, //每页展示条数
      id: "", //搜索公司id
      stu: "", //搜索状态
      page: 1, //当前页码数
      bnum: "",
    };
  },

  mounted() {},
  created() {
    // 获取所有公司
    this.getAllComp();

    // 状态分类统计状态
    this.getAllState();

    // 分页列表查询
    this.getAllList();
  },

  methods: {
    seeIot(row) {
      console.log(row);
      this.bnum = row.num;
      this.iotId = row.id;
      this.assderVisible = true;
      this.$nextTick(() => {
        this.$refs.AlarmCount.getInit(this.bnum);
      });
    },
    handleClick(tab, event) {
      console.log(tab, 1111111111);
      this.activeName = tab.name;
      if (tab == 1) {
      } else if (tab == 2) {
        console.log("这是二");
      }
    },
    getclick(tab) {
      this.activeName = tab.name;
      if (this.activeName == "second") {
        this.$refs.History1.getInit();
      } else if (this.activeName == "seix") {
        this.$refs.DeviceParam.getInit();
      } else if (this.activeName == "eleven") {
        this.$refs.MoveData.getInit();
      }
    },
    toShow(scope) {
      console.log(scope.row.device);
    },
    // 编辑
    handleEdit(a, b) {},
    // 设备资料
    handleData() {},
    // 维保提醒
    handleMind() {},
    // 维保记录
    handleRcord() {},
    // 点击报修
    handleRepair() {},
    // 控制客服弹窗
    assderHandle() {
      this.assderVisible = false;
    },
    // 搜索处理
    seachHandle() {
      // 获取公司id
      this.cities.forEach((item) => {
        if (item.value == this.cityValue) {
          this.id = item.id;
        }
      });
      // 获取状态id
      this.states.forEach((item) => {
        if (item.value == this.stateValue) {
          this.stu = item.value;
          if (item.value == 9999) {
            this.stu = "";
          }
        }
      });

      console.log(this.stateValue, this.stu);
      this.tableData = [];
      this.pageChange(1);
      // this.getAllList(this.id, this.stu, 1, this.pageSize);
    },
    // 导出处理
    exportHandle(fileName) {
      // 获取公司id
      this.cities.forEach((item) => {
        if (item.value == this.cityValue) {
          this.id = item.id;
        }
      });

      // 获取状态id
      // this.states.forEach((item) => {
      //   if (item.value == this.stateValue) {
      //     this.stu = item.id;
      //   }
      // });

      let paramData = {};
      // 只有公司
      if (this.cityValue) {
        paramData = {
          companyId: Number(this.id),
          // status: Number(this.stu),
        };
      }
      // 只有状态
      if (this.stateValue) {
        paramData = {
          // companyId: Number(this.id),
          status: Number(this.stu),
        };
      }
      // 既有公司又有状态
      if (this.cityValue && this.stateValue) {
        paramData = {
          companyId: Number(this.id),
          status: Number(this.stu),
        };
      }

      // console.log(paramData);
      // return;
      // this.$API
      //   .exportAdd(paramData)
      //   .then((res) => {
      //     if (res.code == 1) {
      //     } else {
      //       // this.$message.info(res.msg);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件";
      }
      let param = {
        ...this.queryParam,
      };
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        param["selections"] = this.selectedRowKeys.join(",");
      }
      console.log("导出参数", paramData);

      // if (true) {
      //   this.orderAxios();
      //   return false;
      // }
      this.$API.exportAdd(paramData).then((data) => {
        //console.log(data);
        this.downloadHandle(data);
        // if (!data) {
        //   this.$message.warning("文件下载失败");
        //   return;
        // }
        // if (typeof window.navigator.msSaveBlob !== "undefined") {
        //   window.navigator.msSaveBlob(
        //     new Blob([data], { type: "text/csv,charset=UTF-8" }),
        //     fileName + ".xls"
        //   );
        // } else {
        //   let url = window.URL.createObjectURL(
        //     new Blob([data], { type: "text/csv,charset=UTF-8" })
        //   );
        //   let link = document.createElement("a");
        //   link.style.display = "none";
        //   link.href = url;
        //   link.setAttribute("download", fileName + ".xlsx");
        //   document.body.appendChild(link);
        //   link.click();
        //   document.body.removeChild(link); //下载完成移除元素
        //   window.URL.revokeObjectURL(url); //释放掉blob对象
        // }
        // 组装a标签
        // let elink = document.createElement("a");
        // 设置下载文件名
        // elink.download = "支出汇总表.xls";
        // elink.style.display = "none";
        //  type: "text/csv,charset=utf-8",
        // let blob = new Blob([data], {
        //   type: "text/csv,charset=utf-8",
        // });
        // elink.href = URL.createObjectURL(blob);
        // document.body.appendChild(elink);
        // elink.click();
        // document.body.removeChild(elink);
        // window.URL.revokeObjectURL(blob);
      });
    },
    // orderAxios() {
    //   axios({
    //     method: "get",
    //     url: "https://pc.yfindus.com/jichuang-pc/api/v1/device/device/exportXls",
    //     responseType: "blob",
    //     headers: { },
    //   })
    //     .then((res) => {
    //       console.log(res);
    //       // this.binaryData(res);
    //       this.btnLoadingOne = false;
    //       this.downloadHandle(res);
    //     })
    //     .catch((err) => {
    //       this.btnLoadingOne = false;
    //     });
    // },
    /* 下载文件的公共方法，参数就传blob文件流*/
    downloadHandle(res) {
      return;
      // res.data.code && http.goLogin(res.data.code);
      var blob = new Blob([res], {
        type: "text/csv,charset=UTF-8",
      });
      console.log(blob);
      var downloadElement = document.createElement("a");
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      console.log(href);
      downloadElement.href = href;
      downloadElement.download = "123.xlsx"; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      this.btnLoadingOne = false;
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    },

    // 导出辅助函数
    handleExportXls(fileName) {
      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件";
      }
      let param = {
        ...this.queryParam,
      };
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        param["selections"] = this.selectedRowKeys.join(",");
      }
      console.log("导出参数", param);
      downFile(this.url.exportXlsUrl, param).then((data) => {
        if (!data) {
          this.$message.warning("文件下载失败");
          return;
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(
            new Blob([data], {
              type: "application/vnd.ms-excel",
            }),
            fileName + ".xls"
          );
        } else {
          let url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.ms-excel",
            })
          );
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName + ".xls");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      });
    },
    // 导出辅助函数结束
    // 选择设备
    checkDevice() {},
    // 当前页码改变
    pageChange(page) {
      console.log("page", page);
      this.page = page;
      this.tableData = [];
      this.getAllList();
    },
    // 点击上一页
    prevChange() {},
    // 点击下一页
    nextChange() {},
    // 获取所有公司
    getAllComp() {
      this.$API
        .getAllComp()
        .then((res) => {
          if (res.code == 1) {
            res.data.forEach((item) => {
              this.cities.push({
                value: item.companyName,
                id: item.id,
              });
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 状态分类统计状态
    getAllState() {
      this.$API
        .getAllState()
        .then((res) => {
          // this.states = res.data;
          if (res.code == 1) {
            this.states = res.data;
            console.log(this.states, "9999999");
            this.states.unshift({
              text: "全部",
              title: "全部",
              value: "9999",
            });
            // console.log(this.states, "00000");
            // res.data.forEach((item) => {
            // this.states.push({ value: item.statusName, id: item.status });
            // if (item.status == 0) {
            //   this.startNum = item.num;
            // }
            // if (item.status == 1) {
            //   this.stopNum = item.num;
            // }
            // if (item.status == 2) {
            //   this.repairNum = item.num;
            // }
            // this.totalNum += item.num;
            // });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // 状态分类统计
      this.$API
        .getStatusNum()
        .then((res) => {
          // this.states = res.result;
          if (res.code == 1) {
            res.data.forEach((item) => {
              if (item.status == 0) {
                this.startNum = item.num;
              }
              if (item.status == 1) {
                this.stopNum = item.num;
              }
              if (item.status == 2) {
                this.repairNum = item.num;
              }
              this.totalNum += item.num;
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 分页列表查询
    // 所选择公司id   状态    页码    每页条数
    getAllList() {
      let params = {
        pageNo: this.page,
        pageSize: this.pageSize,
        companyId: this.id,
        status: this.stu,
      };
      this.$API
        .getAllList(params)
        .then((res) => {
          if (res.code == 1) {
            //   {
            //   id: "1",
            //   num: "001",
            //   type: "普通机床",
            //   make: "西门子",
            //   modal: "XCDX-199",
            //   company: "郑州犇犇公司",
            //   date: "2021/10/10",
            //   repair: "2",
            //   device: "维修中",
            //   status: "",
            // }
            this.tableData = [];
            this.listTotal = Number(res.data.total);
            // console.log(this.listTotal);
            res.data.records.forEach((item, index) => {
              // 后台返回的，要保持原样
              // 想前端拼参数，可以加个标识
              this.tableData.push({
                frontIndex: (this.page - 1) * this.pageSize + index + 1,
                id: item.id,
                num: item.deviceNumber, //系统编号
                type: item.typeName,
                make: item.brandName,
                modal: item.deviceModel,
                company: item.companyName,
                date: item.productionDate,
                repair: item.preRepairNum,
                device: item.statusName,
                preRepairNum: item.preRepairNum,
                // 好像是因为id的问题
                status: item.status,
                companyId: item.companyId,
                deviceTypeParentName: item.deviceTypeParentName, //一级设备分类名称
                deviceTypeName: item.deviceTypeName, //二级设备分类名称
              });
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.contaner {
  width: 100%;
  min-width: auto;
}
.device-box {
  width: 100%;

  // 分頁
  .el-pagination {
    text-align: right;
    margin-bottom: 46px;
  }

  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333333;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: red;
    color: #ffffff;
  }

  .red {
    color: #ed1b23;
  }

  .blue {
    color: #67c23a;
  }

  .green {
    color: #e6a23c;
  }

  .b {
    color: #2a89ff;
  }

  .line {
    margin: 0 5px;
  }

  .nav-box {
    padding-top: 36px;

    .pos {
      color: #333333;
      font-size: 16px;
    }
  }

}

// 设备列表
.device-list {
  // height: 224px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;

  .title {
    margin-top: 31px;
    margin-left: 40px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eeeeee;
    font-size: 22px;
    color: #333333;
  }

  .check {
  }

  .company {
    .name {
      margin-right: 20px;
    }

    .el-select {
      width: 256px;
    }
  }

  .statu-name {
    margin-left: 48px;
    margin-right: 20px;
  }

  .search {
    width: 68px;
    color: #ffffff;
    background: #ed1b23;
    margin: 0 12px;
  }
  .action {
    margin-left: auto;
    .item {
    }
  }

  .export {
    width: 88px;
    height: 32px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1577fc;
    border-radius: 4px 4px 4px 4px;

    img {
      width: 12px;
      height: 12px;
      vertical-align: middle;
      margin-right: 4px;
    }

    span {
      position: relative;
      top: 1px;
    }
  }

  //   当前设备
  .current-box {
    // padding-bottom: 24px;
    // background-color: #ffffff;
    .num {
      color: #1577fc;
      margin-right: 27px;
    }

    .btn {
      width: 120px;
      height: 32px;
      margin-right: 20px;
      border-radius: 21px 21px 21px 21px;
    }

    .btn1 {
      background: #0bc197;
      color: #ffffff;
    }

    .btn2 {
      background: #23a8ef;
      color: #ffffff;
    }

    .btn3 {
      background: #bfbfbf;
    }
  }
}

.check-device {
  margin-top: 12px;
  margin-bottom: 12px;

  .tag{
    margin: 0 6px;
  }
  
  .tag:nth-child(1){
    margin-left: 0;
  }

  .check {
    width: 120px;
    background: #1577fc;
    color: #ffffff;

    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}

// 表格
.table-box {
  margin-bottom: 23px;

  // min-height: 238px;
  .handle {
    ul {
      padding: 0 20px;
      display: flex;
      align-items: center;
    }

    li {
      float: left;
      font-size: 14px;
      font-weight: 400;
      color: #2a89ff;
      line-height: 22px;
    }

    .line {
      width: 0px;
      height: 13px;
      border: 1px solid #c6ccda;
      margin: 4px 4px;
    }
  }
}

// 右侧保修 客服
// .fix-right {
//   cursor: pointer;
//   width: 88px;
//   border: 1px solid #ebebeb;
//   position: fixed;
//   z-index: 1000;
//   right: 0;
//   bottom: 266px;
//   // top: 50%;
//   // transform: translateY(-50%);
//   .item1 {
//     background-color: #ffffff;
//   }
//   .item2 {
//     background-color: #ed1b23;
//     z-index: 999;
//   }
//   .item1,
//   .item2 {
//     height: 90px;
//     text-align: center;
//     box-sizing: border-box;
//     padding-top: 9px;
//     // padding-bottom: 16px;
//     img {
//       width: 24px;
//       height: 24px;
//     }
//     p {
//       color: #757575;
//       font-size: 14px;
//       margin-top: 12px;
//     }
//   }
//   .item2 p {
//     color: #ffffff;
//   }
// }
// 自定义弹窗样式
// .assderTc {
//   display: flex;
//   align-items: center;
//   .title {
//     padding-top: 32px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: relative;
//     font-size: 22px;
//     font-weight: bold;
//     color: #333333;
//     img {
//       width: 32px;
//       height: 32px;
//       position: absolute;
//       top: 32px;
//       right: 32px;
//     }
//   }
//   ::v-deep .el-dialog__header {
//     padding: 0;
//   }
//   ::v-deep .el-dialog {
//     border-radius: 0 !important;
//   }
//   ::v-deep .el-dialog__body {
//     padding: 0;
//   }
//   ::v-deep .el-dialog__footer {
//     padding: 0;
//   }
//   ::v-deep .el-dialog {
//     border-radius: 10px;
//   }
//   .ja-c {
//     height: 311px;
//     box-sizing: border-box;
//     padding-left: 214px;
//     padding-top: 52px;
//     p {
//       display: flex;
//       align-items: center;
//       margin-bottom: 32px;
//       img {
//         width: 30px;
//         height: 30px;
//       }
//       span {
//         font-size: 14px;
//         color: #333333;
//         margin-left: 16px;
//       }
//     }
//   }

//   .btnad {
//     width: 112px;
//     height: 40px;
//     background: rgba(246, 247, 249, 1);
//     border: 1px solid #ecedef;
//     opacity: 1;
//     border-radius: 20px;
//   }
//   .btnsad {
//     color: #333;
//     width: 112px;
//     height: 40px;
//     background: rgba(255, 195, 44, 1);
//     opacity: 1;
//     border-radius: 20px;
//   }
// }
// iot
.iot {
  .con {
    box-sizing: border-box;
    padding: 24px 32px;
    padding-top: 12px;
    background: #f9f9f9;
  }

  .subBtn {
    background: #ed1b23 !important;
    border-color: #ed1b23 !important;
  }

  .camera {
    white-space: nowrap;
  }

  .el-form {
    padding: 0px !important;
  }

  .el-form-item__content {
    line-height: normal;
  }

  .el-dialog__body {
    height: 250px;
    padding: 0px;
  }

  .el-dialog__header {
    background: #f9f9f9;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #e2e2e2;
    padding: 0px;
    // padding-top: 18px;
    // padding-bottom: 18px;
    padding-right: 32px;
    padding-left: 32px;

    .title {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333333;
      font-size: 22px;

      img {
        width: 32px;
        height: 32px;
      }

      .line2 {
        width: 4px;
        height: 14px;
        margin-right: 10px;
      }
    }
  }

  .el-form-item {
    margin-bottom: 0px;
  }

  .demo-ruleForm {
    margin-top: 16px;
    margin-left: 32px;
    margin-bottom: 100px;

    // 性別
    .item {
      margin-top: 24px;
      // display: inline-block;
      margin-left: 20px;
      cursor: pointer;
      color: #333333;
      font-size: 14px;

      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }

    .detail-contant {
      margin-top: 20px;
      margin-bottom: 16px;
    }
  }

  .el-textarea__inner {
    width: 686px;
    height: 128px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #999999;
  }

  .btn {
    margin-top: 56px;

    .cancle {
      width: 214px;
      height: 50px;
      background: rgba(255, 0, 21, 0.13);
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #ff0e1e;
      margin-left: 105px;
      color: #ff0015;
      font-size: 16px;
    }

    .submit {
      width: 214px;
      height: 50px;
      background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
      border-radius: 8px 8px 8px 8px;
      margin-left: 48px;
      color: #ffffff;
      font-size: 16px;
    }
  }

  // 详情对话框
  .detail-dialog-box {
    box-sizing: border-box;
    padding: 32px;

    .form-box {
      // 性別
      .item {
        display: inline-block;
        margin-left: 20px;
        cursor: pointer;

        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }

      .item2 {
        margin-left: 56px;
      }

      .file-img {
        width: 94px;
        height: 94px;
        margin-right: 10px;
      }
    }

    .title {
      text-align: center;
      padding-left: 345px;

      p {
        color: #333333;
        font-size: 22px;
      }

      img {
        width: 32px;
        height: 32px;
        // position: relative;
        // top: 8px;
        // right: -300px;
      }
    }

    //对话框垂直居中
    top: 50% !important;
    transform: translateY(-50%);

    // 对话框头部
    .el-dialog__header {
      padding: 0px !important;
    }

    // 对话框内容
    .el-dialog__body {
      padding: 0px !important;
    }

    //input表单样式
    .el-input__inner {
      width: 320px;
      height: 40px;
    }

    //input下边距
    .el-form-item {
      margin-bottom: 20px;
    }

    //label样式
    .el-form-item__label {
      text-align: left;
    }

    //多行文本样式
    .el-textarea__inner {
      width: 536px;
      height: 104px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }

    //按钮样式
    .btn {
    }
  }
}
</style>
