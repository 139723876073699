<template>
	<div class="movepolice">
		<div class="box">
			<!-- <p class="tit">报警明细</p>
      <img class="img" src="@/assets/images/line4.png" alt="" /> -->
			<div class="dat">
				<p>开始结束时间：</p>
				<div class="for">
					<el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" format="yyyy - MM - dd" value-format="yyyy-MM-dd" @change="changeFun">
					</el-date-picker>
				</div>
				<div>
					<el-button plain class="setBtn" @click="resetFun">重置</el-button>
					<el-button class="subBtn" type="primary" @click="getAllList">查询</el-button>
				</div>
			</div>
			<!-- 表格展示 start -->
			<el-row class="table-box">
				<el-table :header-cell-style="{ background: '#F4F4F4', height: '56px' }" :data="tableData" style="width: 100%"
					stripe header-cell-class-name="table-header" :key="Math.random()">
					<el-table-column prop="frontIndex" label="序号" width="54" :key="Math.random()">
					</el-table-column>
					<el-table-column prop="deviceId" label="传感器编号" width="125" :key="Math.random()">
						<template slot-scope="{ row }">{{ row.deviceId || "-" }}</template>
					</el-table-column>
					<el-table-column prop="axisName" label="轴名称" width="126" :key="Math.random()">
						<template slot-scope="{ row }">{{ row.axisName || "-" }}</template>
					</el-table-column>
					<el-table-column prop="standardThreshold" label="标准阈值" width="256" :key="Math.random()">
						<template slot-scope="{ row }">{{
              row.standardThreshold || "-"
            }}</template>
					</el-table-column>
					<el-table-column prop="exceedingThreshold" label="超出阈值" width="159" :key="Math.random()">
						<template slot-scope="{ row }">{{
              row.exceedingThreshold || "-"
            }}</template>
					</el-table-column>
					<el-table-column prop="createTime" label="报警时间" :key="Math.random()">
						<template slot-scope="{ row }">{{
              row.createTime || "-"
            }}</template>
					</el-table-column>
				</el-table>
			</el-row>

			<!-- 表格展示 end -->
			<!-- 分页开始 -->
			<el-pagination background layout="prev, pager, next" :total="listTotal" :page-size="pageSize" :current-page="page"
				@current-change="pageChange" @prev-click="prevChange" @next-click="nextChange">
			</el-pagination>
			<!-- 分页结束 -->
		</div>
	</div>
</template>

<script>
	import * as echarts from "echarts";
	export default {
		props: ["id", "name", "bnum"],
		data() {
			return {
				value1: "",
				input: "",
				tableData: [{}], //表格数据
				listTotal: 0, //列表总数据
				pageSize: 5, //每页展示条数
				page: 1, //当前页码数
				startTime: "",
				endTime: "",
			};
		},
		watch: {},
		mounted() {
			this.setEc1();
		},
		created() {
			if (this.name == "nine") {
				// 分页列表查询
				this.getAllList();
			}
		},
		methods: {
			resetFun() {
				this.value1 = "";
				this.startTime = "";
				this.endTime = "";
				// 分页列表查询
				// this.getAllList();
			},
			// 时间改变函数
			changeFun() {
				console.log(this.value1);
				if (this.value1 && this.value1.length) {
					this.startTime = this.value1[0];
					this.endTime = this.value1[1];
				} else {
					this.startTime = "";
					this.endTime = "";
				}
				// 分页列表查询
				this.getAllList();
			},
			// 分页列表查询
			getAllList() {
				let params = {
					pageNo: this.page,
					pageSize: this.pageSize,
					startTime: this.startTime,
					endTime: this.endTime,
					// status: Number(this.value),
					// alarmNo: this.input,
					// id: Number(this.id),
				};
				this.$API
					.iotResearchCount(params)
					.then((res) => {
						if (res.code == 1) {
							this.tableData = [];
							this.listTotal = Number(res.data.total);
							this.tableData = this.bnum == '24007' ? res.data.records : [];
						} else {
							this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 当前页码改变
			pageChange(page) {
				console.log("page", page);
				this.page = page;
				this.tableData = [];
				this.getAllList();
			},
			// 点击上一页
			prevChange() {},
			// 点击下一页
			nextChange() {},
			setEc1() {
				var chartDom = document.getElementById("ec3");
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					color: [
						"#6379f2",
						"#7bd365",
						"#6095f8",
						"#6e63f2",
						"#fcc46b",
						"#4c8148",
					],
					title: {
						text: "",
					},
					tooltip: {
						trigger: "axis",
					},
					legend: {
						data: [
							"D01 (表面质量相关特征)",
							"D02 (尺寸相关特征10)",
							"D03 (表面质量相关特征)",
							"D04 (尺寸相关特征)",
							"D05 (表面质量相关特征)",
							"D06 (尺寸相关特征)",
						],
					},
					grid: {
						left: "3%",
						right: "4%",
						bottom: "3%",
						containLabel: true,
					},
					// toolbox: {
					//   feature: {
					//     saveAsImage: {},
					//   },
					// },
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
					},
					yAxis: {
						type: "value",
					},
					series: [{
							symbol: "none",
							name: "D01 (表面质量相关特征)",
							type: "line",
							stack: "Total",
							data: [120, 132, 101, 134, 90, 230, 210],
						},
						{
							symbol: "none",

							name: "D02 (尺寸相关特征)",
							type: "line",
							stack: "Total",
							data: [220, 182, 191, 234, 290, 330, 310],
						},
						{
							symbol: "none",

							name: "D03 (表面质量相关特征)",
							type: "line",
							stack: "Total",
							data: [150, 232, 201, 154, 190, 330, 410],
						},
						{
							symbol: "none",

							name: "D04 (尺寸相关特征)",
							type: "line",
							stack: "Total",
							data: [320, 332, 301, 334, 390, 330, 320],
						},
						{
							symbol: "none",

							name: "D05 (表面质量相关特征)",
							type: "line",
							stack: "Total",
							data: [820, 932, 901, 934, 1290, 1330, 1320],
						},
						{
							symbol: "none",

							name: "D06 (尺寸相关特征)",
							type: "line",
							stack: "Total",
							data: [820, 932, 901, 934, 1290, 1330, 1320],
						},
					],
				};
				option && myChart.setOption(option);
			},
		},
	};
</script>

<style lang="scss" scoped>
	// 表格
	.table-box {
		margin-bottom: 23px;

		// min-height: 238px;
		.handle {
			ul {
				padding: 0 20px;
				display: flex;
				align-items: center;
			}

			li {
				float: left;
				font-size: 14px;
				font-weight: 400;
				color: #2a89ff;
				line-height: 22px;
			}

			.line {
				width: 0px;
				height: 13px;
				border: 1px solid #c6ccda;
				margin: 4px 4px;
			}
		}
	}

	.movepolice {
		.box1 {
			height: auto !important;
		}

		.show {
			display: flex;
			flex-wrap: wrap;
		}

		.item {
			width: 345px;
			height: 252px;
			background: linear-gradient(180deg, #fdeded 0%, #fff9f9 11%, #faf7f7 100%);
			border-radius: 16px 16px 16px 16px;
			margin-right: 12px;
			margin-bottom: 12px;
			padding: 20px;
			box-sizing: border-box;
		}

		.img1 {
			width: 24px;
			height: 24px;
			margin-right: 12px;
			position: relative;
			top: 5px;
		}

		.m12 {
			margin-right: 12px;
		}

		.img2 {
			width: 16px;
			height: 16px;
			position: relative;
			top: 3px;
		}

		.line11 {
			display: flex;
			justify-content: space-between;
			padding-bottom: 14px;
			border-bottom: 1px solid #eeeeee;
			font-weight: 400;
			font-size: 14px;
			color: #333333;
			align-items: center;
			vertical-align: middle;
			margin-bottom: 13px;
		}

		.sp {
			margin-right: 12px;
			font-weight: 500;
			font-size: 18px;
			color: #333333;
		}

		.img {
			width: 1088px;
			margin-bottom: 16px;
		}

		.box {
			box-sizing: border-box;
			width: 100%;
			height: 608px;
			background: #ffffff;
			border-radius: 8px 8px 8px 8px;
			padding: 24px;
			margin-bottom: 16px;
		}

		.subBtn {
			background: #ed1b23 !important;
			border-color: #ed1b23 !important;
		}

		.setBtn {
			background: #edefef !important;
			border-color: #edefef !important;
		}

		.tit {
			font-weight: bold;
			color: #333333;
			// padding-bottom: 16px;
			// border-bottom: 1px dotted #eeeeee;
			margin-bottom: 20px;
		}

		.dat {
			display: flex;
			align-items: center;
			// padding-bottom: 16px;
			// border-bottom: 1px dotted #f9f9f9;
			margin-bottom: 16px;
		}

		.for {
			width: 300px;
			margin-left: 20px;
			margin-right: 20px;
		}

		.el-range-editor {
			width: 300px;
		}

		.count {
			color-scheme: #333333;
			margin-bottom: 20px;
		}
	}
</style>