<template>
	<div class="alarmcount">
		<div class="box">
			<p class="tit">月度统计</p>
			<div class="dat">
				<p>开始结束时间:</p>
				<div class="for">
					<el-date-picker v-model="value1" type="monthrange" range-separator="至" start-placeholder="开始月份"
						end-placeholder="结束月份" format="yyyy - MM - dd" value-format="yyyy-MM-dd" @change="changeFun">
					</el-date-picker>
				</div>
				<div>
					<el-button plain class="setBtn" @click="resetFun">重置</el-button>
					<el-button class="subBtn" type="primary" @click="getIotDataPreAlarmData">查询</el-button>
				</div>
			</div>
			<p class="count">报警统计</p>
			<div id="ec111" style="width: 1000px; height: 300px"></div>
		</div>
		<div class="box">
			<p class="tit">周度统计</p>
			<div class="dat">
				<p>开始结束时间:</p>
				<div class="for">
					<el-date-picker v-model="value2" type="week" placeholder="选择周" format="yyyy - MM - dd"
						value-format="yyyy-MM-dd" @change="changeFun1">
					</el-date-picker>
				</div>
				<div>
					<el-button plain class="setBtn" @click="resetFun1">重置</el-button>
					<el-button class="subBtn" type="primary" @click="getIotDataPreAlarmData1">查询</el-button>
				</div>
			</div>
			<p class="count">报警统计</p>
			<div id="ec222" style="width: 1000px; height: 300px"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from "echarts";
	export default {
		props: ["id", "name", "bnum"],

		data() {
			return {
				xzhou2: ["2023-03", "2023-4", "2023-5"],
				yzhou2: [20, 10, 30],
				xzhou1: ["2023-03", "2023-4", "2023-5"],
				yzhou1: [40, 30, 60],
				preList: [],
				value1: "",
				value2: "",
				startTime: "2024-01-31",
				endTime: "2024-03-31",
				startTime1: "2024-01-31",
				endTime1: "2024-03-31",
			};
		},
		watch: {},
		mounted() {
			// this.setEc1();
			// this.setEc2();
		},
		created() {
			if (this.name == "first") {
				// 分页列表查询
				// this.getIotDataPreAlarmData();
				// this.getIotDataPreAlarmData1();
			}
		},
		methods: {
			getInit(bnum) {
				this.getIotDataPreAlarmData(bnum);
				this.getIotDataPreAlarmData1(bnum);
			},
			// 报警预览
			getIotDataPreAlarmData1(bnum) {
				this.$API
					.iotGetAlarmWeekCountFun({
						deviceId: bnum,
						startTime: this.startTime1,
						endTime: this.endTime1,
					})
					.then((res) => {
						if (res.code == 200) {
							this.xzhou2 = bnum == '235386' ? res.data.data.map(item => item[0]) : [];
							this.yzhou2 = bnum == '235386' ? res.data.data.map(item => item[1]) : [];
							// res.data.data.forEach((item) => {
							// 	this.xzhou2.push(item[0]);
							// 	this.yzhou2.push(item[1]);
							// });
							this.setEc2();
						} else {
							// this.$message.info(res.message);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 报警预览
			getIotDataPreAlarmData(bnum) {
				this.$API
					.iotGetAlarmMonthCountFun({
						deviceId: bnum,
						startTime: this.startTime,
						endTime: this.endTime,
					})
					.then((res) => {
						if (res.code == 200) {
							console.log('月统计', res);
							this.xzhou1 = bnum == '235386' ? res.data.data.map(item => item[0]) : [];
							this.yzhou1 = bnum == '235386' ? res.data.data.map(item => item[1]) : [];
							// res.data.data.forEach((item) => {
							// 	this.xzhou1.push(item[0]);
							// 	this.yzhou1.push(item[1]);
							// });
							this.setEc1();
						} else {
							// this.$message.info(res.message);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 重置
			resetFun1() {
				this.value1 = "";
				this.startTime1 = "";
				this.endTime1 = "";
				// 分页列表查询
				this.getIotDataPreAlarmData1();
			},
			// 重置
			resetFun() {
				this.value1 = "";
				this.startTime = "";
				this.endTime = "";
				// 分页列表查询
				this.getIotDataPreAlarmData();
			},
			// 时间改变函数
			changeFun1() {
				if (this.value2 && this.value2.length) {
					this.startTime1 = this.value2[0];
					this.endTime1 = this.value2[1];
				} else {
					this.startTime1 = "";
					this.endTime1 = "";
				}
				// 分页列表查询
				this.getIotDataPreAlarmData1();
			},
			// 时间改变函数
			changeFun() {
				if (this.value1 && this.value1.length) {
					this.startTime = this.value1[0];
					this.endTime = this.value1[1];
				} else {
					this.startTime = "";
					this.endTime = "";
				}
				// 分页列表查询
				this.getIotDataPreAlarmData();
			},
			setEc2() {
				var chartDom = document.getElementById("ec222");
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: this.xzhou2,
					},
					yAxis: {
						type: "value",
					},
					series: [{
						data: this.yzhou2,
						type: "line",
						areaStyle: {
							color: {
								type: "linear",
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [
									// 渐变颜色
									{
										offset: 0,
										color: "#ED1B23",
									},
									{
										offset: 1,
										color: "rgba(237,27,35,0)",
									},
								],
								global: false,
							},
						},
						lineStyle: {
							color: "#ed2129", // 设置线的颜色为红色
						},
						itemStyle: {
							//设置端点颜色
							normal: {
								color: "red", // 设置线条上点的颜色（和图例的颜色）
							},
						},
					}, ],
				};
				option && myChart.setOption(option);
			},
			setEc1() {
				var chartDom = document.getElementById("ec111");
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: this.xzhou1,
					},
					yAxis: {
						type: "value",
					},
					series: [{
						data: this.yzhou1,
						type: "line",
						areaStyle: {
							color: {
								type: "linear",
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [
									// 渐变颜色
									{
										offset: 0,
										color: "#ED1B23",
									},
									{
										offset: 1,
										color: "rgba(237,27,35,0)",
									},
								],
								global: false,
							},
						},
						lineStyle: {
							color: "#ed2129", // 设置线的颜色为红色
						},
						itemStyle: {
							//设置端点颜色
							normal: {
								color: "red", // 设置线条上点的颜色（和图例的颜色）
							},
						},
					}, ],
				};
				console.log(option,'====option====');
				
				option && myChart.setOption(option);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.alarmcount {
		.box {
			box-sizing: border-box;
			width: 100%;
			height: 458px;
			background: #ffffff;
			border-radius: 8px 8px 8px 8px;
			padding: 24px;
			margin-bottom: 16px;
		}

		.subBtn {
			background: #ed1b23 !important;
			border-color: #ed1b23 !important;
		}

		.setBtn {
			background: #edefef !important;
			border-color: #edefef !important;
		}

		.tit {
			font-weight: bold;
			color: #333333;
			padding-bottom: 16px;
			border-bottom: 1px dotted #eeeeee;
			margin-bottom: 20px;
		}

		.dat {
			display: flex;
			align-items: center;
			margin-bottom: 16px;
		}

		.for {
			margin-left: 20px;
			margin-right: 48px;
		}

		.count {
			color-scheme: #333333;
			margin-bottom: 20px;
		}
	}
</style>